import { Component, OnInit } from '@angular/core';

import { SpiderWebChartPageComponent } from '../spider-web-chart-page/spider-web-chart-page.component';

@Component({
  selector: 'app-data-tools-page',
  templateUrl: './data-tools-page.component.html',
  styleUrls: ['./data-tools-page.component.scss']
})
export class DataToolsPageComponent implements OnInit {

  private mainSurveyQueryParams;
  private energySurveyQueryParams;

  constructor() { }

  ngOnInit() {
    this.mainSurveyQueryParams = {
      survey: SpiderWebChartPageComponent.SURVEY_TYPE_MAIN,
    };

    this.energySurveyQueryParams = {
      survey: SpiderWebChartPageComponent.SURVEY_TYPE_LIFESTYLES,
    };
}

}
