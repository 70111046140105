import { HslColor } from './hsl-color';
import { RgbColor } from './rgb-color';
import { GradientColorScheme } from './gradient-color-scheme';

// See also:
// RGB gradient vs HSL gradient: http://www.perbang.dk/rgbgradient/    
// Gradient color generator: https://mycolor.space/?hex=%23326599&sub=1

export class HslGradientColorScheme implements GradientColorScheme {

  stepHslColor: HslColor;

  constructor(
    public minValue: number,
    public maxValue: number,
    public minHslColor: HslColor,
    public maxHslColor: HslColor
  ) { 
    const rangeValue = maxValue - minValue;
    this.stepHslColor = new HslColor(
      (maxHslColor.h - minHslColor.h) / rangeValue,
      (maxHslColor.s - minHslColor.s) / rangeValue,
      (maxHslColor.l - minHslColor.l) / rangeValue
    );
  }

  getHslColor(value: number): HslColor {
    const hslColor = new HslColor(
      this.minHslColor.h + this.stepHslColor.h * (value - this.minValue),
      this.minHslColor.s + this.stepHslColor.s * (value - this.minValue),
      this.minHslColor.l + this.stepHslColor.l * (value - this.minValue)
    );
    return hslColor;
  }

  getRgbColor(value: number): RgbColor {
    return this.getHslColor(value).toRgb();
  }

  validate(value: number): boolean {
    return this.minValue <= value && value <= this.maxValue;
  }

}
