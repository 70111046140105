import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';

import { QuestionEnumValueEntity } from './question-enum-value-entity';

@jsonObject
export class ValueGroupEntity {
  @jsonMember
  uuid: string;

  @jsonMember
  name: string;

  @jsonMember
  label: string;

  @jsonMember
  is_likert_compatible: boolean;

  @jsonArrayMember(QuestionEnumValueEntity)
  enum_values: QuestionEnumValueEntity[];

  static compare(a : ValueGroupEntity, b : ValueGroupEntity) : number {
    return a.name.localeCompare(b.name);
  }

}
