import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Overlay } from '@angular/cdk/overlay';

import { MatDialog, MatButton, MatBadge } from '@angular/material';

import { Observable } from 'rxjs';

import { CountriesSelectorDialogComponent }
                from '../../components/countries-selector-dialog/countries-selector-dialog.component';
import { QuestionsSelectorDialogComponent } from '../../components/questions-selector-dialog/questions-selector-dialog.component';

import { BackendApiService } from '../../services/backend-api.service';

import { CountryEntity } from '../../types/entities/country-entity';
import { QuestionEntity } from '../../types/entities/question-entity';
import { SurveyEntity } from '../../types/entities/survey-entity';

import { CountriesSelection } from '../../types/ui-data/countries-selection';
import { QuestionsSelection } from '../../types/ui-data/questions-selection';

@Component({
  selector: 'app-spider-web-chart-page',
  templateUrl: './spider-web-chart-page.component.html',
  styleUrls: ['./spider-web-chart-page.component.scss']
})
export class SpiderWebChartPageComponent implements OnInit {

  static readonly SURVEY_TYPE_MAIN = 'main';
  static readonly SURVEY_TYPE_LIFESTYLES = 'lifestyles';

  private isMainSurvey: boolean;

  private selectedCountries: CountryEntity[];
  private questionsSelection: QuestionsSelection;
  private constraintQuestions: QuestionEntity[];

  private numberOfCountries: number;
  private numberOfQuestions: number;

  private survey: SurveyEntity;
  private countries_test: CountryEntity[];
  private selectedCountries_test: CountryEntity[];

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private overlay: Overlay,
    private backendApiService: BackendApiService
  ) {
    this.route.queryParams.subscribe(params => {
      // console.log(params);
      this.isMainSurvey = params['survey'] === SpiderWebChartPageComponent.SURVEY_TYPE_MAIN;
    });
  }

  ngOnInit() {
    this.numberOfQuestions = 0;
    this.numberOfCountries = 0;

    const observableSurvey: Observable<SurveyEntity> = this.isMainSurvey ?
      this.backendApiService.getMainSurvey() : this.backendApiService.getLifestylesSurvey();

    observableSurvey
      .subscribe(survey => {
        this.survey = survey;
        this.countries_test = survey.countries;
        this.constraintQuestions = [survey.countryQuestion];
      });

  }

  onSelectCountries(selectedCountries: CountryEntity[]) {
    this.selectedCountries = selectedCountries || [];
    this.numberOfCountries = selectedCountries && selectedCountries.length || 0;
    console.log(`[SpiderWebChartPageComponent.onSelectCountries()] ${this.numberOfCountries}`);
  }

  onSelectQuestions(questionsSelection: QuestionsSelection) {
    if (questionsSelection) {
      this.questionsSelection = questionsSelection;
      this.numberOfQuestions = questionsSelection.questions && questionsSelection.questions.length || 0;
      console.log(`[SpiderWebChartPageComponent.onSelectQuestions()] ${this.numberOfQuestions}`);
    }
  }

  openCountriesSelectorDialog() {
    const dialogRef = this.dialog.open(CountriesSelectorDialogComponent, {
      disableClose: true,
      minHeight: '300px',
      width: '1080px',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      data: new CountriesSelection(this.survey, this.selectedCountries),
      hasBackdrop: true,
    });

    dialogRef.afterClosed().subscribe(result => this.onSelectCountries(result.countries));
  }

  openQuestionsSelectorDialog() {
    if (!this.questionsSelection) {
      this.questionsSelection = new QuestionsSelection(this.survey, true, false, null, null, null, null);
    } else {
      this.questionsSelection.singleValueGroupRestriction = true;
      this.questionsSelection.singleQuestionRestriction = false;
    }

    const dialogRef = this.dialog.open(QuestionsSelectorDialogComponent, {
      disableClose: true,
      minHeight: '700px',
      height : 'auto',
      width: '1080px',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      data: this.questionsSelection,
      hasBackdrop: true,
    });

    dialogRef.afterClosed().subscribe(questionsSelection => this.onSelectQuestions(questionsSelection));
  }

}



