import { Component, OnInit } from '@angular/core';

import { SurveyEntity } from '../../types/entities/survey-entity';

import { MessageService } from '../../services/message.service';
import { WebsiteSurveyService } from '../../services/website-survey.service';

@Component({
  selector: 'app-raw-data-statistics-page',
  templateUrl: './raw-data-statistics-page.component.html',
  styleUrls: ['./raw-data-statistics-page.component.scss']
})
export class RawDataStatisticsPageComponent implements OnInit {

  private survey: SurveyEntity;

  constructor(
    private websiteSurveyService: WebsiteSurveyService,
    private messageService: MessageService) {
  }

  ngOnInit() {

    this.websiteSurveyService
      .getWebsiteSurvey()
      .subscribe(survey => {
        this.survey = survey;
      });

  }

}
