import { Component, OnInit, OnChanges, AfterViewInit, OnDestroy, DoCheck,
         NgZone, Input, IterableDiffers, IterableDiffer, SimpleChanges, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
// import { query } from '@angular/core/src/render3';

// https://www.amcharts.com/docs/v4/getting-started/integrations/using-angular2/
// https://www.amcharts.com/docs/v4/chart-types/map/
// https://www.amcharts.com/docs/v4/concepts/series/#Heat_maps
// https://www.amcharts.com/docs/v4/concepts/legend/heat-legend/
// https://www.amcharts.com/docs/v4/concepts/legend/#Adding_custom_items
// Examples (v3):
// https://codepen.io/team/amcharts/pen/eKbdpK
// https://www.amcharts.com/docs/v3/tutorials/filtering-chart-data-dynamically/
// https://www.amcharts.com/docs/v3/tutorials/dynamically-changing-language-map/
// https://www.amcharts.com/docs/v3/tutorials/dynamically-changing-data-sets-xy-bubble-chart/
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';

import { EchoesUtils } from '../../utils/echoes-utils';
// import {IndicatorDataService} from '../services/indicator-data.service';
import { BackendApiService } from '../../services/backend-api.service';
import { QueryDataService } from '../../services/query-data.service';
// import { CountryService } from '../../services/country.service';
import { MessageService } from '../../services/message.service';
// import { EUROPEAN_COUNTRY_CODES } from '../../services/country.service';

import { CountryEntity } from '../../types/entities/country-entity';
import { QuestionEntity } from '../../types/entities/question-entity';
import { QuestionEnumValueEntity } from '../../types/entities/question-enum-value-entity';
import { SurveyEntity } from '../../types/entities/survey-entity';


import { QueryDataEntity } from '../../types/queries/query-data-entity';
import { QueryDataRecordEntity } from '../../types/queries/query-data-record-entity';
import { RawQueryResultEntity } from '../../types/queries/raw-query-result-entity';

import { MapDataItem } from '../../types/ui-data/map-data-item';

import { HslColor } from '../../color-management/hsl-color';
import { RgbColor } from '../../color-management/rgb-color';
import { GradientColorScheme } from '../../color-management/gradient-color-scheme';
import { HslGradientColorScheme } from '../../color-management/hsl-gradient-color-scheme';
import { RgbGradientColorScheme } from '../../color-management/rgb-gradient-color-scheme';

// interface Dictionary<T> {
//   [key: string]: T;
// }

@Component({
  selector: 'app-heat-map',
  templateUrl: './heat-map.component.html',
  styleUrls: ['./heat-map.component.scss']
})
export class HeatMapComponent implements AfterViewInit, OnChanges, OnDestroy, DoCheck {
  private readonly additionalFilterDropdownlistSettings = {
    singleSelection: false,
    primaryKey: 'value',
    labelKey: 'text',
    text: 'Some text',
    enableSearchFilter: false
  };

  private static getGradientColorScheme(minValue: number, maxValue: number): GradientColorScheme {
    if (maxValue % 2 === 1) {
      maxValue += 1;
    }

    const HEAT_MAP_HSL_COLOR_MIN = new HslColor(359, 50, 80); // pink // new HslColor(335, 60, 80); // rose
    const HEAT_MAP_HSL_COLOR_MAX = new HslColor(60, 100, 60); // yellow
    return new HslGradientColorScheme(
      minValue,
      maxValue,
      HEAT_MAP_HSL_COLOR_MIN,
      HEAT_MAP_HSL_COLOR_MAX
    );

    // const HEAT_MAP_RGB_COLOR_MIN = new RgbColor(255, 0, 0); // red
    // const HEAT_MAP_RGB_COLOR_MAX = new RgbColor(0, 255, 0); // green
    // return new RgbGradientColorScheme(minValue, maxValue, HEAT_MAP_RGB_COLOR_MIN, HEAT_MAP_RGB_COLOR_MAX);
  }

  private static getGradientColor(
    gradientColorScheme: GradientColorScheme,
    value: number,
    useMoreGradientColor: boolean
  ): am4core.Color {
    const roundedValue = EchoesUtils.Math.round(value, useMoreGradientColor ? 2 : 0);
    const rgbColor: RgbColor = gradientColorScheme.getRgbColor(roundedValue);
    return am4core.color(rgbColor);
  }

  @Input()
  survey: SurveyEntity;

  @Input()
  indicatorQuestion: QuestionEntity;

  @Input()
  constraintQuestions: QuestionEntity[];

  private rawQueryResultCache: Observable<RawQueryResultEntity>;
  private queryData: QueryDataEntity;

  private constraintQuestionsDiffer: IterableDiffer<QuestionEntity>;

  private chart: am4maps.MapChart;
  private useMoreGradientColor: boolean;
  private useAdditionalFilters: boolean;

  private selectedQuestionEnumValues: QuestionEnumValueEntity[][];

  constructor(
    private zone: NgZone,
    private backendApiService: BackendApiService,
    private queryDataService: QueryDataService,
    // private countryService: CountryService,
    private messageService: MessageService,
    private iterableDiffers: IterableDiffers
  ) {
    this.constraintQuestionsDiffer = this.iterableDiffers.find([]).create(null);
  }

  ngOnInit() {

    if (this.constraintQuestions) {
      this.initSelectedQuestionEnumValues();
    }

  //   this.backendApiService.getSurvey()
  //     .subscribe(survey => {
  //       this.countryQuestion = survey.getCountryQuestion();

  //       console.log(this.messageService.debug(
  //         'HeatMapComponent', 'ngOnInit()', 'this.countryQuestion !== null', !!this.countryQuestion));
  //     });
  }

  displayChart() {
    console.log(this.messageService.debug('HeatMapComponent', 'ngOnChanges()', 'start'));

    // this.data = this.indicatorService.getData(this.indicator);

    if (!this.constraintQuestions || this.constraintQuestions.length === 0 || !this.indicatorQuestion) {
      console.error(this.messageService.error(
        'HeatMapComponent', 'ngOnChanges()', 'this.constraintQuestions or this.indicatorQuestion is undefined', this.constraintQuestions));
      return;
    }

    if (!this.rawQueryResultCache) {
      this.rawQueryResultCache = this.queryDataService
        .executeQueriesEx(this.survey, this.constraintQuestions, [this.indicatorQuestion]);
    }

    this.rawQueryResultCache
      .subscribe(rawQueryResult => {
        if (!rawQueryResult || !this.chart || !this.chart.series || this.chart.series.length === 0) {
          return;
        }

        // define constraintQuestionFilterValues
        const constraintQuestionFilterValues: number[][] = [];
        for (const constraintQuestion of rawQueryResult.constraintQuestions) {
          const selectedEnumValues: QuestionEnumValueEntity[] = this.selectedQuestionEnumValues[constraintQuestion.name];
          if (selectedEnumValues && selectedEnumValues.length < constraintQuestion.enum_values.length) {
            constraintQuestionFilterValues.push(selectedEnumValues.map(v => v.value));
          } else {
            constraintQuestionFilterValues.push(null);
          }
        }

        // console.log(this.messageService.debug(
        //   'HeatMapComponent', 'displayChart()', 'constraintQuestionFilterValues', constraintQuestionFilterValues));

        this.queryData = this.queryDataService.processQueryResult(rawQueryResult, constraintQuestionFilterValues);

        // console.log(this.messageService.debug(
        //   'HeatMapComponent', 'displayChart()', 'this.queryData', this.queryData));

        this.zone.runOutsideAngular(() => {
          this.updateChartDataSeries();
        });
      });
  }

  private updateChartDataSeries(): void {
    const indicatorQuestionEnumValues: QuestionEnumValueEntity[] = this.indicatorQuestion.enum_values.sort(
      QuestionEnumValueEntity.compare
    );

    const minValue: number = indicatorQuestionEnumValues[0].value;
    const maxValue: number =
      indicatorQuestionEnumValues[indicatorQuestionEnumValues.length - 1].value;
    const gradientColorScheme: GradientColorScheme = HeatMapComponent.getGradientColorScheme(
      minValue,
      maxValue
    );
    const useMoreGradientColor = this.useMoreGradientColor;

    
    /* Add legend */
    if (this.chart.legend) {
      this.chart.legend.dispose();
    }
    const legend = (this.chart.legend = new am4maps.Legend());
    // legend.parent = this.chart.chartContainer;
    legend.background.fill = am4core.color('#000');
    legend.background.fillOpacity = 0.1;
    legend.width = 250;
    legend.position = 'left';
    legend.valign = 'top';
    legend.align = 'left';
    legend.padding(10, 15, 10, 15);
    legend.draggable = true;
    legend.data = [];
    for (let enumValue of indicatorQuestionEnumValues) {
      legend.data.push({
        name: `${enumValue.value}-${enumValue.text}`,
        fill: HeatMapComponent.getGradientColor(
          gradientColorScheme,
          enumValue.value,
          useMoreGradientColor
        )
      });
    }

    legend.itemContainers.template.clickable = false;
    legend.itemContainers.template.focusable = false;

    // var legendTitle = legend.createChild(am4core.Label);
    // legendTitle.text = "Legends:";

    const valueField: string =
      QueryDataRecordEntity.formatFieldName(this.indicatorQuestion, QueryDataRecordEntity.VALUE_FIELD);
    const textField: string = QueryDataRecordEntity.formatFieldName(this.indicatorQuestion, QueryDataRecordEntity.TEXT_FIELD);
    const countryCountField: string = QueryDataRecordEntity.formatFieldName(this.indicatorQuestion, QueryDataRecordEntity.COUNTRY_COUNT_FIELD);
    const filteredCountField: string = QueryDataRecordEntity.formatFieldName(this.indicatorQuestion, QueryDataRecordEntity.FILTERED_COUNT_FIELD);
    const distributionField: string = QueryDataRecordEntity.formatFieldName(this.indicatorQuestion, QueryDataRecordEntity.DISTRIBUTION_FIELD);

    const polygonSeries: am4maps.MapPolygonSeries = this.chart.series.getIndex(
      0
    ) as am4maps.MapPolygonSeries;
    polygonSeries.dataFields.id = 'id';
    polygonSeries.dataFields.value = valueField;


    const polygonTemplate: am4maps.MapPolygon = polygonSeries.mapPolygons.template;
    // const messageService = this.messageService;
    polygonTemplate.tooltipText = `[bold]{name}[/]
      -----
      Average answer: [bold]{${valueField}.formatNumber("#.##")}[/] ({${textField}})
      Answers counted: [bold]{${filteredCountField}}[/] of {${countryCountField}}
      Distribution: {${distributionField}}`;

    // polygonTemplate.tooltipHTML = `<img src="http://localhost:4200/assets/img/misc/man_on_map_02.jpg" />`;

    polygonTemplate.adapter.add('fill', function(fill, target) {
      const data = target.dataItem.dataContext;
      if (data) {
        let value = data[valueField];
        if (value && gradientColorScheme && gradientColorScheme.validate(value)) {
          return HeatMapComponent.getGradientColor(
            gradientColorScheme,
            value,
            useMoreGradientColor
          );
        } else {
          return fill; // current fill
        }
      } else {
        // return default fill (line pattern with stroke 45o)
        const pattern = new am4core.LinePattern();
        pattern.width = 10;
        pattern.height = 10;
        pattern.stroke = am4core.color('#28527B'); // blue-dark
        pattern.strokeWidth = 1;
        pattern.rotation = 45;
        return pattern;
      }
    });

    // polygonSeries.data = [];
    // this.chart.validateData();
    polygonSeries.data = this.queryData.records;
    this.chart.validateData();
  }

  // private getHeatMapData(queryData: QueryDataEntity) {
  //   const indicatorQuestion: QuestionEntity = queryData.indicatorQuestions[0];
  //   const indicatorQuestionValueFieldName: string = QueryDataService.formatQuestionValueFieldName(indicatorQuestion);
  //   const indicatorQuestionTextFieldName: string = QueryDataService.formatQuestionTextFieldName(indicatorQuestion);

  //   const heatMapData = [];

  //   for (const country of queryData.countries) {
      
  //     const records: QueryDataRecordEntity[] = queryData.records.filter(r => r.id === country.code);
  //     if (records.length > 0) {
  //       const heatMapDataRecord: QueryDataRecordEntity = new QueryDataRecordEntity();
  //       const counts: number[] = [];
  //       let totalSum: number = 0;
  //       let totalCount: number = 0;
  //       for (const record of records) {
  //         const enumValue: number = record[indicatorQuestionValueFieldName] as number;
  //         const count: number = record.count;
  //         totalCount += count;
  //         totalSum += enumValue * count;
  //       }
  //       const mean: number = totalSum * 1.0 / totalCount;
  //       heatMapDataRecord.id = country.code;
  //       heatMapDataRecord.count = totalCount;
  //       heatMapDataRecord[indicatorQuestionValueFieldName] = mean;        
  //     }
  //   }
  // }

  ngAfterViewInit() {
    console.log(this.messageService.debug('HeatMapComponent', 'ngAfterViewInit()', 'start'));
    this.zone.runOutsideAngular(() => {
      const container: am4core.Container = am4core.create('chartdiv', am4core.Container);
      container.width = am4core.percent(100);
      container.height = am4core.percent(100);
      container.layout = 'vertical';

      // title
      /*const title = container.createChild(am4core.Label);
      title.text = "Test indicator";
      title.fontSize = 20;
      title.paddingTop = 30;
      title.paddingBottom = 30;
      title.align = "center";*/

      //map
      const chart: am4maps.MapChart = container.createChild(am4maps.MapChart);
      //chart.seriesContainer.draggable = false;
      //chart.seriesContainer.resizable = false;
      //chart.maxZoomLevel = 1;

      chart.geodata = am4geodata_worldLow;
      // chart.projection = new am4maps.projections.Miller();
      chart.projection = new am4maps.projections.NaturalEarth1();
      // chart.projection = am4maps.projections.Projection();

      const polygonSeries: am4maps.MapPolygonSeries = chart.series.push(
        new am4maps.MapPolygonSeries()
      );
      // const polygonTemplate: am4maps.MapPolygon = polygonSeries.mapPolygons.template;
      // // polygonTemplate.tooltipText = "{name}: {value.value.formatNumber('#')}";
      // polygonTemplate.tooltipText = "{name}: {Q46_1$textValue} ({value.formatNumber('#')})";
      // polygonSeries.heatRules.push({
      //   property: 'fill',
      //   target: polygonSeries.mapPolygons.template,
      //   // min: am4core.color('#EEE8A9'),
      //   // max: am4core.color('#28527B'),
      //   min: am4core.color('#F88'),
      //   max: am4core.color('#0F0'),
      //   minValue: 1,
      //   maxValue: 5,
      // });

      polygonSeries.useGeodata = true;

      // only Europe
      polygonSeries.include = CountryEntity.EUROPEAN_COUNTRY_CODES;

      // can be delayed sometimes
      polygonSeries.include = this.survey.countries.map(country => country.code);

      // // add heat legend
      // const heatLegend: am4maps.HeatLegend = container.createChild(am4maps.HeatLegend);
      // heatLegend.valign = 'bottom';
      // heatLegend.align = 'right';
      // heatLegend.width = am4core.percent(100);
      // heatLegend.series = polygonSeries;
      // heatLegend.orientation = 'horizontal';
      // heatLegend.margin(20, 20, 20, 20);
      // heatLegend.valueAxis.renderer.labels.template.fontSize = 10;
      // heatLegend.valueAxis.renderer.minGridDistance = 40;
      // heatLegend.markerCount = 5;
      // heatLegend.minValue = 1;
      // heatLegend.maxValue = 5;

      // //functions
      // polygonSeries.mapPolygons.template.events.on('over', event => {
      //   handleHover(event.target);
      // });

      // polygonSeries.mapPolygons.template.events.on('hit', event => {
      //   handleHover(event.target);
      // });

      // function handleHover(mapPolygon) {
      //   if (!isNaN(mapPolygon.dataItem.value)) {
      //     heatLegend.valueAxis.showTooltipAt(mapPolygon.dataItem.value);
      //   } else {
      //     heatLegend.valueAxis.hideTooltip();
      //   }
      // }

      // polygonSeries.mapPolygons.template.strokeOpacity = 0.4;
      // polygonSeries.mapPolygons.template.events.on('out', event => {
      //   heatLegend.valueAxis.hideTooltip();
      // });

      chart.zoomControl = new am4maps.ZoomControl();

      // data
      // polygonSeries.data = this.data;
      this.chart = chart;
    });
  }

  ngDoCheck(): void {
    if (this.constraintQuestionsDiffer.diff(this.constraintQuestions)) {
      this.rawQueryResultCache = undefined;
      this.initSelectedQuestionEnumValues();
    }
  }

  private initSelectedQuestionEnumValues(): void {
    if (!this.selectedQuestionEnumValues) {
      this.selectedQuestionEnumValues = [];
    }

    for (const question of this.constraintQuestions) {        
      if (!this.selectedQuestionEnumValues[question.name]) {
        this.selectedQuestionEnumValues[question.name] = [...question.enum_values]; // clone values
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.rawQueryResultCache = undefined;
  }

  // https://ultimatecourses.com/blog/exploring-angular-lifecycle-hooks-ondestroy
  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  // private onChangeAdditionalFilter(event: any): void {
  //   console.error(event.source);
  // }
}
