import { QuestionEntity } from '../entities/question-entity';
import { TagEntity } from '../entities/tag-entity';

export class NormalizedQuestion {

  static toNormalizedQuestions(questions: QuestionEntity[], tagFilter?: TagEntity[]): NormalizedQuestion[] {
    if (!questions) {
      return null;
    }

    const normalizedQuestions = [];
    for (const question of questions) {
      if (question.tags) {
        for (const tag of question.tags) {
          if (!tagFilter || tagFilter.includes(tag)) {
            normalizedQuestions.push(new NormalizedQuestion(question, tag.name));
          }
        }
      // } else {
      //   normalizedQuestions.push(new NormalizedQuestion(question, null));
      }
    }
    return normalizedQuestions;
  }

  public shortLabelWithName: string;

  constructor(
    public question: QuestionEntity,
    public tag: string
  ) {
    this.shortLabelWithName = question.getShortLabelWithName();    
  }

}
