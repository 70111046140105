// import { CountryEntity } from '../entities/country-entity';
import { QuestionEntity } from '../entities/question-entity';
import { QuestionEnumValueEntity } from '../entities/question-enum-value-entity';

export class QueryDataRecordEntity {

    static readonly VALUE_FIELD: string = 'value';
    static readonly TEXT_FIELD: string = 'text';
    static readonly COUNTRY_FIELD: string = 'country';

    static readonly COUNTRY_COUNT_FIELD: string = 'countryCount';
    static readonly FILTERED_COUNT_FIELD: string = 'filteredCount';
    static readonly DISTRIBUTION_FIELD: string = 'distribution';

    static formatFieldName(question: QuestionEntity, fieldName: string): string {
        return `${question.name}$$${fieldName}`;
    }

    static formatFieldNameByEnumValue(question: QuestionEntity, enumValue: QuestionEnumValueEntity) {
      return `${question.name}$$${enumValue.value}`;
    }

    // Note: do not remove change this field, 
    // it is required for normal work of target.dataItem.dataContext in am4chart
    id: string;
    country: string;
    // count: number;
    // countryCode: string;
    // text: string;
    // value: number;
    // values: QuestionEnumValueEntity[];
    [propName: string]: number | string; //QuestionEnumValueEntity[]; // number | string;
    // value: number;
}
