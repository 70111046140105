export class ValueDistribution {

  static readonly VALUE_INDEX: number = 0;
  static readonly COUNT_INDEX: number = 1;

  valueCounts: number[][] = [];
  totalCount: number = 0;

  add(value: number, count: number): number {
    this.totalCount += count;

    let valueCount = this.valueCounts.find(valueCount => valueCount[ValueDistribution.VALUE_INDEX] === value);
    if (!valueCount) {
      this.valueCounts.push([value, count]);
      this.valueCounts.sort((a, b) => a[ValueDistribution.VALUE_INDEX] - b[ValueDistribution.VALUE_INDEX]);
      return count;
    }
    return (valueCount[ValueDistribution.COUNT_INDEX] += count);
  }

  toString(): string {
    const items: string[] = this.valueCounts.map(valueCount => {
      const [value, count] = valueCount;
      const percentage = count / this.totalCount * 100;
      // return `${value}: ${percentage.toFixed(1)}%`;
      return `[bold]${value}[/] (${percentage.toFixed(1)}%)`;
    });
    return items.join('; ');
  }
}
