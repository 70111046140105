import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-download-query-data',
  templateUrl: './download-query-data.component.html',
  styleUrls: ['./download-query-data.component.scss']
})
export class DownloadQueryDataComponent implements OnInit {

  // TODO: implement examples:
  // Excel:
  // https://www.c-sharpcorner.com/article/generate-and-download-excel-file-in-angular-7/
  // http://thecodehubs.com/generate-and-download-excel-file-in-angular-7/

  // JSON:
  // https://stackoverflow.com/questions/51806464/how-to-create-and-download-text-json-file-with-dynamic-content-using-angular-2/51806590
  

  // CSV:
  // https://stackoverflow.com/questions/51487689/angular-5-how-to-export-data-to-csv-file
  // https://stackoverflow.com/questions/40966096/angular-2-download-csv-file-click-event-with-authentication/40966227

  constructor() { }

  ngOnInit() {
  }

}
