import { Component, OnInit } from '@angular/core';
// import { APP_BASE_HREF } from '@angular/common';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-decision-making-page',
  templateUrl: './decision-making-page.component.html',
  styleUrls: ['./decision-making-page.component.scss']
})
export class DecisionMakingPageComponent implements OnInit {

  // TODO: implement pictures using https://www.npmjs.com/package/ngx-lightbox
  // or using https://www.w3schools.com/howto/howto_css_modal_images.asp

  private static readonly SAMPLE_COUNTRIES: string[] = [ 'Austria', 'Bulgaria', 'Finland', 'Norway', 'Spain', 'Turkey' ];

  private images;

  constructor(
    // @Inject(APP_BASE_HREF) private baseHref: string,
    private lightbox: Lightbox) {

    this.images =
    [
      {
        caption: 'Austria',
        // src: baseHref + 'assets/img/decision-making/Austria_network.png',
        // thumb: baseHref + 'assets/img/decision-making/Austria_network_small.jpg',
        src: 'assets/img/decision-making/Austria_network.jpg',
        thumb: 'assets/img/decision-making/Austria_network_small.jpg',
      },
      {
        caption: 'Bulgaria',
        src: 'assets/img/decision-making/Bulgaria_network.jpg',
        thumb: 'assets/img/decision-making/Bulgaria_network_small.jpg',
      },
      {
        caption: 'Finland',
        src: 'assets/img/decision-making/Finland_network.jpg',
        thumb: 'assets/img/decision-making/Finland_network_small.jpg',
      },
      {
        caption: 'Norway',
        src: 'assets/img/decision-making/Norway_network.jpg',
        thumb: 'assets/img/decision-making/Norway_network_small.jpg',
      },
      {
        caption: 'Spain',
        src: 'assets/img/decision-making/Spain_network.jpg',
        thumb: 'assets/img/decision-making/Spain_network_small.jpg',
      },
      {
        caption: 'Turkey',
        src: 'assets/img/decision-making/Turkey_network.jpg',
        thumb: 'assets/img/decision-making/Turkey_network_small.jpg',
      },
    ]; 

  }

  ngOnInit() {
  }

  openImage(index: number): void {
    console.log("openImage: " + this.images[index].src);
    // open lightbox
    this.lightbox.open(this.images, index, {
      showImageNumberLabel: true
    });
  }
 
  close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }

}
