import { Component, OnInit, Input, NgZone, HostListener } from '@angular/core';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { QuestionEntity } from '../../types/entities/question-entity';
import { SurveyEntity } from '../../types/entities/survey-entity';

// import { RawQueryResultEntity } from '../../types/queries/raw-query-result-entity';
import { QueryDataEntity } from '../../types/queries/query-data-entity';
import { QueryDataRecordEntity } from '../../types/queries/query-data-record-entity';

import { MessageService } from '../../services/message.service';
import { WebsiteSurveyService } from '../../services/website-survey.service';

@Component({
  selector: 'app-raw-data-download-chart',
  templateUrl: './raw-data-download-chart.component.html',
  styleUrls: ['./raw-data-download-chart.component.scss']
})
export class RawDataDownloadChartComponent implements OnInit {
  private static readonly INDICATOR_NAME_FIELD: string = 'indicatorName';

  @Input()
  survey: SurveyEntity;

  private queryData: QueryDataEntity;
  private chart: am4charts.XYChart;
  private totalCount: number;

  constructor(
    private zone: NgZone,
    private websiteSurveyService: WebsiteSurveyService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    console.log(this.messageService.debug('SpiderWebChartComponent', 'ngAfterViewInit()', 'start'));
    this.zone.runOutsideAngular(() => {
      // set themes
      am4core.useTheme(am4themes_animated);

      // create chart
      // https://www.amcharts.com/docs/v4/chart-types/xy-chart/
      const chart: am4charts.XYChart = am4core.create('chart', am4charts.XYChart);
      this.chart = chart;
    });

    this.websiteSurveyService.executeQueriesEx(this.survey).subscribe(rawQueryResult => {
      if (!rawQueryResult || !this.chart) {
        return;
      }

      this.queryData = this.websiteSurveyService.processQueryResult(rawQueryResult);

      console.log(this.messageService.debug('RawDataDownloadChartComponent', 'ngOnInit', 'this.queryData.records', this.queryData.records));

      this.zone.runOutsideAngular(() => {
        this.updateDataSeries(this.chart, this.queryData);
      });

    });
  }

  private updateDataSeries(chart: am4charts.XYChart, queryData: QueryDataEntity) {

    // Create axes
    // https://stackoverflow.com/questions/53910595/how-to-use-amcharts4-in-angular6
    chart.xAxes.clear();
    chart.yAxes.clear();

    // https://www.amcharts.com/docs/v4/chart-types/xy-chart/
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = 'Downloads';

    const indicatorQuestions: QuestionEntity[] = this.survey.getIndicatorQuestions();

    // set series
    chart.series.clear();

    const categoryDataField = 'id'; // 'country';
    categoryAxis.dataFields.category = categoryDataField;
    categoryAxis.title.text = 'Countries';

    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;

    // add series
    for (const question of indicatorQuestions) {
      const questionValueField: string = QueryDataRecordEntity.formatFieldName(question, QueryDataRecordEntity.VALUE_FIELD);

      for (const enumValue of question.enum_values) {
        const series: am4charts.ColumnSeries = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = categoryDataField;

        const fieldName: string = QueryDataRecordEntity.formatFieldNameByEnumValue(question, enumValue);
        series.dataFields.valueY = fieldName;

        series.name = enumValue.text;
        series.tooltipText = `{name}: [bold]{valueY}[/]`;
        console.log(this.messageService.debug('RawDataDownloadChartComponent', 'updateDataSeries()',
          'series.dataFields.valueY', fieldName));

        series.stacked = true;

        series.strokeWidth = 3;
        series.zIndex = 2;
        series.sequencedInterpolation = true;
        series.sequencedInterpolationDelay = 100;
      }
    }

    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    // Add legend
    chart.legend = new am4charts.Legend();

    // set data
    // console.log(this.messageService.debug('RawDataDownloadChartComponent', 'updateDataSeries()', 'queryData.records', queryData.records));
    chart.data = queryData.records;
    this.totalCount = queryData.totalCount;
  }

  // https://ultimatecourses.com/blog/exploring-angular-lifecycle-hooks-ondestroy
  @HostListener('window:beforeunload')
  ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

}

