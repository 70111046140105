import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { BackendApiService } from './backend-api.service';
import { BackendUrlService } from './backend-url.service';
import { MessageService } from './message.service';
import { QueryDataService } from './query-data.service';
import { StatisticsService } from './statistics.service';

import { CountryEntity } from '../types/entities/country-entity';
import { SurveyEntity } from '../types/entities/survey-entity';
import { QuestionEntity } from '../types/entities/question-entity';

import { QueryDataEntity } from '../types/queries/query-data-entity';
import { QueryDataRecordEntity } from '../types/queries/query-data-record-entity';
import { RawQueryResultEntity } from '../types/queries/raw-query-result-entity';


@Injectable({
  providedIn: 'root'
})
export class WebsiteSurveyService {

  private backendApiService: BackendApiService;
  private backendUrlService: BackendUrlService;

  private observableWebsiteSurveyEntity: Observable<SurveyEntity>;

  constructor(
    private queryDataService: QueryDataService,
    private messageService: MessageService,
    private statisticsService: StatisticsService
  ) {
    this.backendApiService = queryDataService.backendApiService;
    this.backendUrlService = this.backendApiService.backendUrlService;    
  }

  getWebsiteSurvey(): Observable<SurveyEntity> {
    if (!this.observableWebsiteSurveyEntity) {
      this.observableWebsiteSurveyEntity = this.backendApiService.getSurvey(BackendUrlService.WEBSITE_SURVEY_UUID);
    }
    return this.observableWebsiteSurveyEntity;
  }

  insertSurveyAnswers(surveyEntity: SurveyEntity, answers: number[]): Observable<{}> {
    return this.backendApiService.insertSurveyAnswers(surveyEntity, answers);
  }

  executeQueriesEx(surveyEntity: SurveyEntity): Observable<RawQueryResultEntity> {
    return this.queryDataService.executeQueriesFullEx(surveyEntity);    
  }

  processQueryResult(rawQueryResult: RawQueryResultEntity): QueryDataEntity {
    
    const queryData = new QueryDataEntity();
    queryData.rawQueryResult = rawQueryResult;
    queryData.records = [];
    queryData.totalCount = 0;

    const countries: CountryEntity[] = rawQueryResult.countries;

    for (const indicator of rawQueryResult.indicators) {
      const question: QuestionEntity = indicator.indicatorQuestion;

      for (const country of countries) {
        for (const indicatorValues of indicator.values) {
          const countryNumber: number = indicatorValues.constraints[0];
          if (country.number === countryNumber) {
            for (const valueCount of indicatorValues.value_counts) {
              const [value, count] = valueCount;

              let record: QueryDataRecordEntity = queryData.records.find(r => r.id === country.code);
              if (!record) {
                record = new QueryDataRecordEntity();
                record.id = country.code;
                record.country = country.name;
                queryData.records.push(record);
              }

              const enumValue = question.enum_values.find(v => v.value === value);
              if (enumValue) {
                const fieldName: string = QueryDataRecordEntity.formatFieldNameByEnumValue(question, enumValue);
                record[fieldName] = count;
                queryData.totalCount += count;                
                // console.error(`country: ${country.name}, value: ${enumValue.text}, count: ${count}`);
              }
            }
          }
        }
      }
    }

    return queryData;
  }
}
