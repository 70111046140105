import { CategoryEntity } from '../entities/category-entity';
import { QuestionEntity } from '../entities/question-entity';
import { SurveyEntity } from '../entities/survey-entity';
import { TagEntity } from '../entities/tag-entity';
import { ValueGroupEntity } from '../entities/value-group-entity';

import { NormalizedQuestion } from './normalized-question';
// import { QuestionHierarchy } from '../../types/question-hierarchy';

export class QuestionsSelection {
  constructor(
    public survey: SurveyEntity,
    public singleValueGroupRestriction: boolean,
    public singleQuestionRestriction: boolean,
    public categories: CategoryEntity[],
    public tags: TagEntity[],
    public valueGroups: ValueGroupEntity[],
    public questions: QuestionEntity[],
    public normalizedQuestion?: NormalizedQuestion
  ) {}
}
