import { Component, OnInit, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { HotkeysService, Hotkey } from 'angular2-hotkeys';

import { CountriesSelection } from '../../types/ui-data/countries-selection';

@Component({
  selector: 'app-countries-selector-dialog',
  templateUrl: './countries-selector-dialog.component.html',
  styleUrls: ['./countries-selector-dialog.component.scss']
})
export class CountriesSelectorDialogComponent implements OnInit {

  private selectedData: CountriesSelection

  constructor(
    public dialogRef: MatDialogRef<CountriesSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CountriesSelection,
    private hotkeysService: HotkeysService) {

    if (!data) {
      throw new Error("data is undefined");
    }

    this.selectedData = data.clone();

    this.hotkeysService.add(new Hotkey('esc', (event: KeyboardEvent): ExtendedKeyboardEvent => {
      this.closeDialog(false);

      const e: ExtendedKeyboardEvent = event;
      e.returnValue = false; // Prevent bubbling
      return e;
    }));

    this.hotkeysService.add(new Hotkey('ctrl+enter', (event: KeyboardEvent): ExtendedKeyboardEvent => {
      if (this.isSelected()) {
        this.closeDialog(true);
      }

      const e: ExtendedKeyboardEvent = event;
      e.returnValue = false; // Prevent bubbling
      return e;
    }));
  }

  ngOnInit() {
  }

  private onSelectCountries(selection: CountriesSelection) {
    // this.selected = true;
    this.selectedData = selection;
  }

  private closeDialog(select: boolean) {
    this.dialogRef.close(select ? this.selectedData : this.data);
  }

  private isSelected(): boolean {
    return this.selectedData && this.selectedData.countries && this.selectedData.countries.length > 0;
  }

}
