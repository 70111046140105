import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-download-page',
  templateUrl: './download-page.component.html',
  styleUrls: ['./download-page.component.scss']
})
export class DownloadPageComponent implements OnInit {

  private noSurvey: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router) {
    this.route.queryParams.subscribe(params => {
      this.noSurvey = params['nosurvey'] === 'true';
    });
  }

  ngOnInit() {
    if (!this.noSurvey) {
      this.router.navigate(['/raw-data']);
    }
  }

}
