
import { jsonObject, jsonMember } from 'typedjson';

import { QueryEntity } from './query-entity';

export const CATEGORY_IDENTIFIER : string = 'Identifier';
export const CATEGORY_DEMOGRAPHIC : string = 'Demographic';

@jsonObject
export class CategoryEntity {

  static isConstraint(category : CategoryEntity) : boolean {
    return category.name === CATEGORY_IDENTIFIER || name === CATEGORY_DEMOGRAPHIC;
  }

  static isActive(category : CategoryEntity) : boolean {
    return !CategoryEntity.isConstraint(category);
  }

  static compare(a : CategoryEntity, b : CategoryEntity) : number {
    return (a.name > b.name) ? 1 :
              (a.name < b.name) ? -1 : 0;
  }

  @jsonMember
  uuid: string;

  @jsonMember
  name: string;

  // queries? : QueryEntity[];

}