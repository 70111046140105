// import 'core-js';

import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';

import { CategoryEntity } from './category-entity';
import { CountryEntity } from './country-entity';
import { QuestionEntity } from './question-entity';
import { QueryEntity } from './query-entity';
import { TagEntity } from './tag-entity';
import { ValueGroupEntity } from './value-group-entity';

@jsonObject
export class SurveyEntity
{
  @jsonMember
  uuid: string;

  @jsonMember
  name: string;

  @jsonMember
  description: string;

  @jsonArrayMember(TagEntity)
  tags: TagEntity[];

  @jsonArrayMember(CategoryEntity)
  categories: CategoryEntity[];

  @jsonArrayMember(ValueGroupEntity)
  value_groups: ValueGroupEntity[];

  @jsonArrayMember(QuestionEntity)
  questions: QuestionEntity[];

  @jsonArrayMember(QueryEntity)
  queries: QueryEntity[];

  countryQuestion: QuestionEntity;
  countries: CountryEntity[];
  isMainSurvey: boolean;

  // Method init() is called by backendServiceApi
  init(): SurveyEntity {
    if (this.questions) {
      for (const question of this.questions) {
        question.init(this);
        if (question.isCountryQuestion()) {
          this.countryQuestion = question;
          this.countries = CountryEntity.getCountriesFromCountryQuestion(question);
        }
      }
    } else {
      this.questions = [];
    }

    if (this.queries) {
      for (const query of this.queries) {
        query.init(this);
      }
    } else {
      this.queries = [];
    }

    if (!this.categories) {
      this.categories = [];
    }

    if (!this.tags) {
      this.tags = [];
    }

    if (!this.value_groups) {
      this.value_groups = [];
    }

    return this;
  }

  /* Categories */
  getActiveCategories(): CategoryEntity[] {
    return this.categories
          .filter(CategoryEntity.isActive);
  }

  /* Tags */
  getActiveTags(): TagEntity[] {
    return this.tags; // .filter(TagEntity.isActive);
  }

  /* Value Groups */
  getLikertValueGroups(): ValueGroupEntity[] {
    return this.value_groups.filter(valueGroup => valueGroup.is_likert_compatible);
  }

  /* Questions */
  getQuestionByUuid(uuid: string): QuestionEntity {
    if (this.questions) {
      return this.questions.find(question => question.uuid === uuid);
    } else {
      throw new Error(`Undefined this.questions: ('${this}')`);
    }
  }

  getQuestionsByValueGroup(valueGroup: ValueGroupEntity) : QuestionEntity[] {
    if (this.questions) {
      return this.questions.filter(question => question.value_group_uuid === valueGroup.uuid);
    } else {
      throw new Error(`Undefined this.questions: ('${this}')`);
    }
  }

  getQuestionsByCategory(category: CategoryEntity): QuestionEntity[] {
    return this.questions.filter(question => question.category_uuid === category.uuid);
  }

  getQuestionsByValueGroupAndCategory(valueGroup: ValueGroupEntity, category: CategoryEntity): QuestionEntity[] {
    if (this.questions) {
      return this.getQuestionsByValueGroup(valueGroup).filter(q => q.value_group_uuid === valueGroup.uuid);
    } else {
      throw new Error(`Undefined this.questions: ('${this}')`);
    }
  }

  getIndicatorQuestions(): QuestionEntity[] {
    if (this.questions) {
      return this.questions.filter(q => !q.isConstraintQuestion());
    } else {
      throw new Error(`Undefined this.questions: ('${this}')`);
    }
  }

  getConstraintQuestions(): QuestionEntity[] {
    if (this.questions) {
      return this.questions.filter(q => q.isConstraintQuestion());
    } else {
      throw new Error(`Undefined this.questions: ('${this}')`);
    }
  }

  /* Queries */
  getQueriesByValueGroup(valueGroup: ValueGroupEntity) : QueryEntity[] {
    if (this.queries) {
      return this.queries.filter(query => {
        const question: QuestionEntity = this.getQuestionByUuid(query.variable_uuid);
        return question && question.value_group_uuid === valueGroup.uuid;
      });
    } else {
      throw new Error(`Undefined this.queries: ('${this}')`);
    }
  }

  getQueriesByCategory(category: CategoryEntity): QueryEntity[] {
    return this.queries
          .filter(query => query.category_uuid === category.uuid);
  }

  getQueriesByValueGroupAndCategory(valueGroup: ValueGroupEntity, category: CategoryEntity): QueryEntity[] {
    if (this.queries) {
      return this.getQueriesByValueGroup(valueGroup).filter(query => {
        const question: QuestionEntity = this.getQuestionByUuid(query.variable_uuid);
        return question && question.value_group_uuid === valueGroup.uuid;
      });
    } else {
      throw new Error(`Undefined this.queries: ('${this}')`);
    }
  }

}
