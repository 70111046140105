import { Component, OnInit } from '@angular/core';

import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-kpi-page',
  templateUrl: './kpi-page.component.html',
  styleUrls: ['./kpi-page.component.scss']
})
export class KpiPageComponent implements OnInit {

  private images;

  constructor(private lightbox: Lightbox) { 
    this.images =
    [
      {
        // KPI 02
        caption: 'Individual intention to commit in collective action together with other consumers or providers of energy',
        src: 'assets/img/kpi/kpi02.png',
      },
      {
        // KPI 05
        caption: '% of customers that have switched suppliers to increased share of RES energy per year',
        src: 'assets/img/kpi/kpi05.png',
      },
      {
        // KPI 07
        caption: 'Have energy efficiency renovations been made to your dwelling',
        src: 'assets/img/kpi/kpi07.png',
      },
      {
        // KPI 08
        caption: 'Residential heat consumption',
        src: 'assets/img/kpi/kpi08.png',
      },
      {
        // KPI 09
        caption: 'Residential electricity consumption',
        src: 'assets/img/kpi/kpi09.png',
      },
      {
        // KPI 10
        caption: 'Public transport use',
        src: 'assets/img/kpi/kpi10.png',
      },
      {
        // KPI 14
        caption: 'Arrears on utility bills',
        src: 'assets/img/kpi/kpi14.png',
      },
      {
        // KPI 17
        caption: 'Dwelling comfortably warm during winter time',
        src: 'assets/img/kpi/kpi17.png',
      },
      {
        // KPI 18
        caption: 'Dwelling comfortably cool during summer time',
        src: 'assets/img/kpi/kpi18.png',
      },
      {
        // KPI 19
        caption: 'Presence of leak, damp, rot in dwelling - indicating low energy efficiency in dwelling',
        src: 'assets/img/kpi/kpi19.png',
      },
      {
        // KPI 21
        caption: 'Gender pay gap in the energy sector',
        src: 'assets/img/kpi/kpi21.png',
      },
      {
        // KPI 22
        caption: 'Perception of seriousness of climate change in present',
        src: 'assets/img/kpi/kpi22.png',
      },
      {
        // KPI 26
        caption: 'Perception of acting together to achieve energy transition',
        src: 'assets/img/kpi/kpi26.png',
      },
    ];   

  }

  openImage(index: number): void {
    console.log("openImage: " + this.images[index].src);
    // open lightbox
    this.lightbox.open(this.images, index, {
      showImageNumberLabel: true
    });
  }

  ngOnInit() {
  }

}
