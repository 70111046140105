import { Component, Inject, Input, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { HotkeysService, Hotkey } from 'angular2-hotkeys';

import { QuestionsSelection } from '../../types/ui-data/questions-selection';

@Component({
  selector: 'app-questions-selector-dialog',
  templateUrl: './questions-selector-dialog.component.html',
  styleUrls: ['./questions-selector-dialog.component.scss']
})
export class QuestionsSelectorDialogComponent implements OnInit {

  private selected: boolean;
  private selectedData: QuestionsSelection;

  constructor(
    public dialogRef: MatDialogRef<QuestionsSelectorDialogComponent>,    
    @Inject(MAT_DIALOG_DATA) public data: QuestionsSelection,
    private hotkeysService: HotkeysService) {

    if (!data) {
      throw new Error("data is undefined");
    }

    this.selectedData = data;

    this.hotkeysService.add(new Hotkey('esc', (event: KeyboardEvent): ExtendedKeyboardEvent => {
      this.closeDialog(false);

      const e: ExtendedKeyboardEvent = event;
      e.returnValue = false; // Prevent bubbling
      return e;
    }));

    this.hotkeysService.add(new Hotkey('ctrl+enter', (event: KeyboardEvent): ExtendedKeyboardEvent => {
      if (this.isSelected()) {
        this.closeDialog(true);
      }

      const e: ExtendedKeyboardEvent = event;
      e.returnValue = false; // Prevent bubbling
      return e;
    }));
  }

  ngOnInit(): void {
  }

  private onSelectQuestions(questionsSelection: QuestionsSelection) {
    this.selected = true;
    this.selectedData = questionsSelection;
  }

  private closeDialog(select: boolean) {
    this.dialogRef.close(select ? this.selectedData : this.data);
  }

  private isSelected(): boolean {
    return this.selectedData && this.selectedData.questions && this.selectedData.questions.length > 0
  }

}
