import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';

import { MessageService } from '../../services/message.service';

import { CountryEntity } from '../../types/entities/country-entity';
import { SurveyEntity } from '../../types/entities/survey-entity';

import { CountriesSelection } from '../../types/ui-data/countries-selection';

@Component({
  selector: 'app-countries-selector',
  templateUrl: './countries-selector.component.html',
  styleUrls: ['./countries-selector.component.scss']
})
export class CountriesSelectorComponent implements OnInit {

  private countries: CountryEntity[];

  @Input()
  survey: SurveyEntity;

  @Input()
  selectedCountries: CountryEntity[];
  
  @Output()
  selectionChange = new EventEmitter<CountriesSelection>();

  private readonly countriesDropdownlistSettings = {
        singleSelection: false,
        primaryKey: 'code',
        labelKey: 'name',
        text: 'Select Countries',
        enableSearchFilter: true,
        // classes:"",
      };

  constructor(
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    if (!this.survey) {
      throw new Error("survey is undefined");
    }
    this.countries = this.survey.countries;
  }

  private onSelectCountries(event: any): void {
    console.log(this.messageService.debug(
      'CountriesSelectorComponent', 'onSelectCountries()', 'Emitting event', this.selectedCountries));
    const selectedCountries: CountryEntity[] = this.selectedCountries.sort(CountryEntity.compare);
    this.selectionChange.emit(new CountriesSelection(this.survey, selectedCountries));
  }

}
