// import { HslColor } from './color-management/hsl-color';
// import { RgbColor } from './color-management/rgb-color';

export class Constants {

  // static readonly CREDENTIALS: string = btoa(USER_NAME + ':' + PASSWORD);
  static readonly CREDENTIALS: string = 'ZWNob2VzOmRlbW8=';
  
  static readonly ENABLE_DEBUG_DETAILS: boolean = true;

}
