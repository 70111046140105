import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { BackendApiService } from './backend-api.service';
import { MessageService } from './message.service';
import { StatisticsService } from './statistics.service';

import { SurveyEntity } from '../types/entities/survey-entity';
import { QueryEntity } from '../types/entities/query-entity';
import { QuestionEntity } from '../types/entities/question-entity';
import { QuestionEnumValueEntity } from '../types/entities/question-enum-value-entity';
import { QueryDataEntity } from '../types/queries/query-data-entity';
import { QueryDataRecordEntity } from '../types/queries/query-data-record-entity';
import { QueryResultEntity } from '../types/queries/query-result-entity';
import { QueryResultIndicatorEntity } from '../types/queries/query-result-indicator-entity';
import { RawQueryDataEntity } from '../types/queries/raw-query-data-entity';
import { RawQueryDataRecordEntity } from '../types/queries/raw-query-data-record-entity';
import { RawQueryResultEntity } from '../types/queries/raw-query-result-entity';

import { MapDataItem } from '../types/ui-data/map-data-item';

import { ValueDistribution } from '../types/queries/value-distribution';
import { CountryEntity } from '../types/entities/country-entity';

import { Constants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class QueryDataService {
  constructor(
    public readonly backendApiService: BackendApiService,
    public readonly messageService: MessageService,
    public readonly statisticsService: StatisticsService
  ) {}

  // executeQuery(queryEntity: QueryEntity): Observable<QueryDataEntity> {
  //   return this.backendApiService
  //     .getSurvey()
  //     .pipe(
  //       mergeMap(survey =>
  //         this.backendApiService
  //           .executeQuery(queryEntity)
  //           .pipe(map(rawQueryData => this.processQueryDataEntity(survey, rawQueryData)))
  //       )
  //     );
  // }

  // executeQueries(queryEntities: QueryEntity[]): Observable<QueryDataEntity> {
  //   return this.backendApiService
  //     .getSurvey()
  //     .pipe(
  //       mergeMap(survey =>
  //         this.backendApiService
  //           .executeQueries(queryEntities)
  //           .pipe(map(rawQueryData => this.processQueryDataEntity(survey, rawQueryData)))
  //       )
  //     );
  // }

  executeQueriesEx(surveyEntity: SurveyEntity, constraintQuestions: QuestionEntity[], indicatorQuestions: QuestionEntity[]): Observable<RawQueryResultEntity> {
    if (!surveyEntity) {
      throw new Error('surveyEntity is undefined');
    }

    if (!constraintQuestions) {
      throw new Error('constraintQuestions is undefined');
    }

    if (!indicatorQuestions) {
      throw new Error('indicatorQuestions is undefined');
    }

    return this.backendApiService
            .executeQueriesEx(surveyEntity, constraintQuestions, indicatorQuestions)
            .pipe(map(rawQueryResult => rawQueryResult.init(surveyEntity)));
  }

  executeQueriesFullEx(surveyEntity: SurveyEntity): Observable<RawQueryResultEntity> {
    if (!surveyEntity) {
      throw new Error('surveyEntity is undefined');
    }

    const constraintQuestions: QuestionEntity[] = surveyEntity.getConstraintQuestions();
    const indicatorQuestions: QuestionEntity[] = surveyEntity.getIndicatorQuestions();

    return this.executeQueriesEx(surveyEntity, constraintQuestions, indicatorQuestions);
  }

  private validateConstraintValues(constraintValues: number[], constraintQuestionFilterValues: number[][]): boolean {
    if (constraintQuestionFilterValues) {
      for (let i = 0; i < constraintQuestionFilterValues.length; ++i) {      
        const filterValues: number[] = constraintQuestionFilterValues[i];
        if (filterValues && !filterValues.includes(constraintValues[i])) {
          return false;        
        }
      }
    }

    return true;
  }

  processQueryResult(rawQueryResult: RawQueryResultEntity,
                    constraintQuestionFilterValues: number[][]): QueryDataEntity {
    
    const queryData = new QueryDataEntity();
    queryData.rawQueryResult = rawQueryResult;
    queryData.minValue = undefined;
    queryData.maxValue = undefined;
    queryData.records = [];

    let countries: CountryEntity[] = rawQueryResult.countries;

    if (constraintQuestionFilterValues && constraintQuestionFilterValues[rawQueryResult.countryQuestionIndex]) {
      countries = countries.filter(c => constraintQuestionFilterValues[rawQueryResult.countryQuestionIndex].includes(c.number));
    }

    for (const indicator of rawQueryResult.indicators) {
      const question: QuestionEntity = indicator.indicatorQuestion;
      const indicatorValueFieldName = QueryDataRecordEntity.formatFieldName(question, QueryDataRecordEntity.VALUE_FIELD);
      const indicatorTextFieldName = QueryDataRecordEntity.formatFieldName(question, QueryDataRecordEntity.TEXT_FIELD);
      const indicatorTotalCountFieldName = QueryDataRecordEntity.formatFieldName(question, QueryDataRecordEntity.COUNTRY_COUNT_FIELD);
      const indicatorFilteredCountFieldName = QueryDataRecordEntity.formatFieldName(question, QueryDataRecordEntity.FILTERED_COUNT_FIELD);
      const indicatorDistributionFieldName = QueryDataRecordEntity.formatFieldName(question, QueryDataRecordEntity.DISTRIBUTION_FIELD);

      // console.log(this.messageService.debug(
      //   'QueryDataService', 'processQueryResult', 'countries', countries));

      for (const country of countries) {

        let totalCountryCount: number = 0;        
        const filteredValueDistribution = new ValueDistribution();

        for (const indicatorValues of indicator.values) {
          const countryNumber = indicatorValues.constraints[rawQueryResult.countryQuestionIndex];
          if (country.number === countryNumber) {
            // filter value-count pairs
            const filtered: boolean = this.validateConstraintValues(indicatorValues.constraints, constraintQuestionFilterValues);
            for (const valueCount of indicatorValues.value_counts) {
              const [value, count] = valueCount;
              totalCountryCount += count;
              if (filtered) {
                if (!queryData.minValue || value < queryData.minValue) {
                  queryData.minValue = value;
                }

                if (!queryData.maxValue || value > queryData.maxValue) {
                  queryData.maxValue = value;
                }

                filteredValueDistribution.add(value, count);
              }
            }
          }
        }

        if (totalCountryCount > 0) {
          // calculate the mean value
          const average: number = this.statisticsService.getAverageOfValueDistribution(filteredValueDistribution);
          // const average: number = totalFilteredValue / totalCountryCount;
          // console.log(this.messageService.debug(
          //   'QueryDataService', 'processQueryResult()', 'sum, count', `${sum}, ${count}`));

          // create or update data record
          let record: QueryDataRecordEntity = queryData.records.find(r => r.id === country.code);
          if (!record) {
            record = new QueryDataRecordEntity();
            record.id = country.code;
            record.country = country.name;
            queryData.records.push(record);
          }
          record[indicatorValueFieldName] = average;

          if (question.enum_values) {
            const roundedAverage: number = Math.round(average);
            const roundedAverageEnum: QuestionEnumValueEntity = question.enum_values.find(v => v.value === roundedAverage);        
            if (roundedAverageEnum) {
              record[indicatorTextFieldName] = roundedAverageEnum.text;
            }
          }

          record[indicatorTotalCountFieldName] = totalCountryCount;
          record[indicatorFilteredCountFieldName] = filteredValueDistribution.totalCount;
          record[indicatorDistributionFieldName] = filteredValueDistribution.toString();
        }

      }

    }

    return queryData;
  }

  // private processQueryResultEntity(survey: SurveyEntity, rawQueryResult: RawQueryResultEntity): QueryResultEntity {

  //   console.log(this.messageService.debug(
  //     'QueryResultService', 'processQueryResultEntity()', 'rawQueryResult', rawQueryResult));

  //   if (!rawQueryResult) {
  //     return undefined;
  //   }

  //   const queryResult = new QueryResultEntity();

  //   //
  //   // set queryResult.constraintQuestions
  //   //
  //   if (rawQueryResult.constraint_uuids) {
  //     queryResult.constraintQuestions = rawQueryResult.constraint_uuids.map(uuid => survey.getQuestionByUuid(uuid));
  //     // console.log(this.messageService.debug(
  //     //   'QueryResultService', 'processQueryResultEntity()', 'queryResult.constraintQuestions', queryResult.constraintQuestions));
  //   } else {
  //     throw new Error(`Undefined rawQueryResult.constraint_uuids: ('${rawQueryResult}')`);
  //   }

  //   //
  //   // detect the country question and set queryResult.countries
  //   //
  //   let countries: CountryEntity[];
  //   for (const constraintQuestion of queryResult.constraintQuestions) {
  //     if (constraintQuestion.isCountryQuestion()) {
  //       queryResult.countryQuestion = constraintQuestion;
  //       queryResult.countries = this.countryService.getCountriesFromQuestionEnumValues(queryResult.countryQuestion);
  //       countries = queryResult.countries;
  //       // console.log(this.messageService.debug(
  //       //   'QueryResultService', 'processQueryResultEntity()', 'countries', queryResult.countries));
  //       break;
  //     }
  //   }

  //   if (!queryResult.countryQuestion) {
  //     throw new Error(`Undefined country question ('${QuestionEntity.COUNTRY_QUESTION_NAME}')`);
  //   }

  //   //
  //   // set queryResult.indicators
  //   //
  //   queryResult.indicators = [];
  //   if (rawQueryResult.indicators) {
  //     for (const rawIndicator of rawQueryResult.indicators) {
  //       const indicator = new QueryResultIndicatorEntity();
  //       indicator.indicatorQuestion = survey.getQuestionByUuid(rawIndicator.indicator_uuid);
  //       let index = 0;

  //       for (const constraintQuestion of queryResult.constraintQuestions) {
  //         const numberValue: number = rawIndicator.values[index++];
  //         if (constraintQuestion.isCountryQuestion()) {
  //           const country: CountryEntity = countries.find(c => c.id === numberValue);
  //           indicator.id = country.code;
  //           indicator.country = country.name;
  //         } else {
  //           const roundedValue = Math.round(numberValue);
  //           const enumValue: QuestionEnumValueEntity = constraintQuestion.enum_values.find(ev => ev.value === roundedValue);
  //           indicator[QueryResultService.formatQuestionValueFieldName(constraintQuestion)] = numberValue;
  //           indicator[QueryResultService.formatQuestionTextFieldName(constraintQuestion)] = enumValue ? enumValue.text : undefined;
  //         }
  //       }

  //       for (const indicatorQuestion of queryResult.indicatorQuestions) {
  //         const numberValue: number = rawIndicator.values[index++];
  //         const roundedValue = Math.round(numberValue);
  //         const enumValue: QuestionEnumValueEntity = indicatorQuestion.enum_values.find(ev => ev.value === roundedValue);
  //         indicator[QueryResultService.formatQuestionValueFieldName(indicatorQuestion)] = numberValue;
  //         indicator[QueryResultService.formatQuestionTextFieldName(indicatorQuestion)] = enumValue ? enumValue.text : undefined;
  //         // indicator.value = numberValue;
  //         // indicator.text = enumValue.text;
  //       }

  //       indicator.count = rawIndicator.values[index];
  //       queryResult.indicators.push(indicator);
  //     }
  //   }

  //   console.log(this.messageService.debug(
  //     'QueryResultService', 'processQueryResultEntity()', 'queryResult.indicators', queryResult.indicators));

  //   return queryResult;
  // }

  // private processQueryDataEntity(survey: SurveyEntity, rawQueryData: RawQueryDataEntity): QueryDataEntity {

  //   console.log(this.messageService.debug(
  //     'QueryDataService', 'processQueryDataEntity()', 'rawQueryData', rawQueryData));

  //   if (!rawQueryData) {
  //     return undefined;
  //   }

  //   const queryData = new QueryDataEntity();

  //   //
  //   // set queryData.constraintQuestions
  //   //
  //   if (rawQueryData.constraint_question_uuids) {
  //     queryData.constraintQuestions = rawQueryData.constraint_question_uuids.map(uuid => survey.getQuestionByUuid(uuid));
  //     // console.log(this.messageService.debug(
  //     //   'QueryDataService', 'processQueryDataEntity()', 'queryData.constraintQuestions', queryData.constraintQuestions));
  //   } else {
  //     throw new Error(`Undefined rawQueryData.constraint_question_uuids: ('${rawQueryData}')`);
  //   }

  //   //
  //   // set queryData.indicatorQuestions
  //   //
  //   if (rawQueryData.indicator_question_uuids) {
  //     queryData.indicatorQuestions = rawQueryData.indicator_question_uuids.map(uuid => survey.getQuestionByUuid(uuid));
  //     // console.log(this.messageService.debug(
  //     //   'QueryDataService', 'processQueryDataEntity()', 'queryData.indicatorQuestions', queryData.indicatorQuestions));
  //   } else {
  //     throw new Error(`Undefined rawQueryData.indicator_question_uuids: ('${rawQueryData}')`);
  //   }

  //   //
  //   // set queryData.countries
  //   //
  //   let countries: CountryEntity[];
  //   for (const constraintQuestion of queryData.constraintQuestions) {
  //     if (constraintQuestion.isCountryQuestion()) {
  //       queryData.countryQuestion = constraintQuestion;
  //       queryData.countries = this.countryService.getCountriesFromQuestionEnumValues(queryData.countryQuestion);
  //       countries = queryData.countries;
  //       // console.log(this.messageService.debug(
  //       //   'QueryDataService', 'processQueryDataEntity()', 'countries', queryData.countries));
  //       break;
  //     }
  //   }

  //   if (!queryData.countryQuestion) {
  //     throw new Error(`Undefined country question ('${QuestionEntity.COUNTRY_QUESTION_NAME}')`);
  //   }

  //   //
  //   // set queryData.records
  //   //
  //   queryData.records = [];
  //   if (rawQueryData.records) {
  //     for (const rawRecord of rawQueryData.records) {
  //       const record = new QueryDataRecordEntity();
  //       let index = 0;

  //       for (const constraintQuestion of queryData.constraintQuestions) {
  //         const numberValue: number = rawRecord.values[index++];
  //         if (constraintQuestion.isCountryQuestion()) {
  //           const country: CountryEntity = countries.find(c => c.id === numberValue);
  //           record.id = country.code;
  //           record.country = country.name;
  //         } else {
  //           const roundedValue = Math.round(numberValue);
  //           const enumValue: QuestionEnumValueEntity = constraintQuestion.enum_values.find(ev => ev.value === roundedValue);
  //           record[QueryDataService.formatQuestionValueFieldName(constraintQuestion)] = numberValue;
  //           record[QueryDataService.formatQuestionTextFieldName(constraintQuestion)] = enumValue ? enumValue.text : undefined;
  //         }
  //       }

  //       for (const indicatorQuestion of queryData.indicatorQuestions) {
  //         const numberValue: number = rawRecord.values[index++];
  //         const roundedValue = Math.round(numberValue);
  //         const enumValue: QuestionEnumValueEntity = indicatorQuestion.enum_values.find(ev => ev.value === roundedValue);
  //         record[QueryDataService.formatQuestionValueFieldName(indicatorQuestion)] = numberValue;
  //         record[QueryDataService.formatQuestionTextFieldName(indicatorQuestion)] = enumValue ? enumValue.text : undefined;
  //         // record.value = numberValue;
  //         // record.text = enumValue.text;
  //       }

  //       record.count = rawRecord.values[index];
  //       queryData.records.push(record);
  //     }
  //   }

  //   console.log(this.messageService.debug(
  //     'QueryDataService', 'processQueryDataEntity()', 'queryData.records', queryData.records));

  //   return queryData;
  // }

  // getCountryDataForQuery(country: CountryEntity, query: QuestionEntity) {
  //   return Math.random();
  // }

}
