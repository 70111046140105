import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BackendUrlService {

  static readonly MAIN_SURVEY_UUID = 'db78ab4e-3dca-11e9-b210-d663bd873d93';
  static readonly LIFESTYLES_SURVEY_UUID = 'b3acc49f-5233-4fc2-9976-ea1290f7d86b';
  static readonly WEBSITE_SURVEY_UUID = 'a935d9f8-86ba-45f5-ac1b-5a0876121045';

  private static readonly BASE: string = '/echoes-backend/';
  private static readonly EXPORT_FULL: string = 'export-full/';
  private static readonly QUERIES: string = 'queries/';
  private static readonly EXECUTE: string = 'execute/';
  private static readonly INSERT: string = 'insert/';
  private static readonly ANSWERS: string = 'answers/';

  constructor() { }

  getSurveyUrl(surveyUuid: string): string {
      return BackendUrlService.BASE + surveyUuid + '/';
  }

  getSurveyFullUrl(surveyUuid: string): string {
      return this.getSurveyUrl(surveyUuid) + BackendUrlService.EXPORT_FULL;
  }

  getQueriesUrl(surveyUuid: string): string {
    return this.getSurveyUrl(surveyUuid) + BackendUrlService.QUERIES;
  }

  getQueriesExecuteUrl(surveyUuid: string): string {
    return this.getQueriesUrl(surveyUuid) + BackendUrlService.EXECUTE;
  }

  getQueryUrl(surveyUuid: string, queryUuid: string): string {
    return this.getQueriesUrl(surveyUuid) + queryUuid + '/';
  }

  getQueryExecuteUrl(surveyUuid: string, queryUuid: string): string {
    return this.getQueryUrl(surveyUuid, queryUuid) + BackendUrlService.EXECUTE;
  }

  getAnswersUrl(surveyUuid: string): string {
      return this.getSurveyUrl(surveyUuid) + BackendUrlService.ANSWERS;
  }

  getAnswersInsertUrl(surveyUuid: string): string {
      return this.getAnswersUrl(surveyUuid) + BackendUrlService.INSERT;
  }

}

