import { Component, OnInit, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { HotkeysService, Hotkey } from 'angular2-hotkeys';

import { QuestionEntity } from '../../types/entities/question-entity';

import { ConstraintQuestionsSelection } from '../../types/ui-data/constraint-questions-selection';

@Component({
  selector: 'app-constraint-questions-selector-dialog',
  templateUrl: './constraint-questions-selector-dialog.component.html',
  styleUrls: ['./constraint-questions-selector-dialog.component.scss']
})
export class ConstraintQuestionsSelectorDialogComponent implements OnInit {

  private selectedData: ConstraintQuestionsSelection;

  constructor(
    public dialogRef: MatDialogRef<ConstraintQuestionsSelectorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConstraintQuestionsSelection,
    private hotkeysService: HotkeysService) {

    if (!data) {
      throw new Error("data is undefined");
    }

    if (!data.survey) {
      throw new Error("data.survey is undefined");
    }

    this.selectedData = data;

    this.hotkeysService.add(new Hotkey('esc', (event: KeyboardEvent): ExtendedKeyboardEvent => {
      this.closeDialog(false);

      const e: ExtendedKeyboardEvent = event;
      e.returnValue = false; // Prevent bubbling
      return e;
    }));

    this.hotkeysService.add(new Hotkey('ctrl+enter', (event: KeyboardEvent): ExtendedKeyboardEvent => {
      this.closeDialog(true);

      const e: ExtendedKeyboardEvent = event;
      e.returnValue = false; // Prevent bubbling
      return e;
    }));
  }

  ngOnInit() {
  }

  onSelectQuestions(constraintQuestionsSelection: ConstraintQuestionsSelection) {
    this.selectedData = constraintQuestionsSelection;
  }

  closeDialog(select: boolean) {
    this.dialogRef.close(select ? this.selectedData : this.data);
  }

}
