import { Component, EventEmitter, OnInit, Input, Output, ViewChild } from '@angular/core';

import { MatListOption, MatSelectionListChange } from '@angular/material/list';

import { MessageService } from '../../services/message.service';

import { QuestionEntity } from '../../types/entities/question-entity';
import { SurveyEntity } from '../../types/entities/survey-entity';

import { ConstraintQuestionsSelection } from '../../types/ui-data/constraint-questions-selection';

@Component({
  selector: 'app-constraint-questions-selector',
  templateUrl: './constraint-questions-selector.component.html',
  styleUrls: ['./constraint-questions-selector.component.scss']
})
export class ConstraintQuestionsSelectorComponent implements OnInit {

  @Input()
  survey: SurveyEntity;

  @Input()
  selectedQuestions: QuestionEntity[];

  @Output()
  selectionChange = new EventEmitter<ConstraintQuestionsSelection>();

  private constraintQuestions: QuestionEntity[];

  constructor(
    private messageService: MessageService
  ) {}

  ngOnInit() {
    if (!this.survey) {
      throw new Error("survey is undefined");
    }

    this.constraintQuestions = this.survey.getConstraintQuestions(); // .filter(q => !q.isCountryQuestion());
    if (!this.selectedQuestions) {
      this.selectedQuestions = [this.survey.countryQuestion];
      const selection = new ConstraintQuestionsSelection(
        this.survey,
        this.selectedQuestions
      );
      this.selectionChange.emit(selection);
    }
  }

  private onSelectQuestions(event: MatSelectionListChange) {
    const selectedOptions: MatListOption[] = event.source.selectedOptions.selected;
    this.selectedQuestions = selectedOptions.map(option => option.value as QuestionEntity);

      const selection = new ConstraintQuestionsSelection(
        this.survey,
        this.selectedQuestions
      );

    this.selectionChange.emit(selection);
  }
}
