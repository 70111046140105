import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';

import { EchoesUtils } from '../../utils/echoes-utils';

import { SurveyEntity } from './survey-entity';
import { CategoryEntity } from './category-entity';
import { TagEntity } from './tag-entity';
import { QuestionEntity } from './question-entity';

@jsonObject
export class QueryEntity {
  static compare(a : QueryEntity, b : QueryEntity) : number {
    return (a.name > b.name) ? 1 :
              (a.name < b.name) ? -1 : 0;
  }

  @jsonMember
  uuid: string;

  @jsonMember
  name: string;

  @jsonMember
  short_label: string;

  @jsonMember
  label: string;

  @jsonMember
  aggregate_method: string;

  @jsonMember
  order_number: number;

  @jsonMember
  kind: string;

  @jsonMember
  category_uuid: string;

  // @jsonMember
  category: CategoryEntity;

  @jsonMember
  variable_uuid: string;

  // @jsonMember
  variable: QuestionEntity;

  @jsonArrayMember(String)
  tags_uuids: string[];

  // @jsonArrayMember(String)
  tags: TagEntity[];

  init(survey: SurveyEntity): QueryEntity {
    if (this.category_uuid) {
      this.category = survey.categories.find(c => c.uuid === this.category_uuid);
    }

    this.tags = [];
    if (this.tags_uuids) {
      for (const tag_uuid of this.tags_uuids) {
        const tag: TagEntity = survey.tags.find(t => t.uuid === tag_uuid);
        this.tags.push(tag);
      }
    }

    if (this.variable_uuid) {
      this.variable = survey.questions.find(v => v.uuid === this.variable_uuid);
    }

    return this;
  }

  getShortLabel() {
    const label: string = this.short_label ? this.short_label : this.label;
    return EchoesUtils.String.formatShortString(`${this.name}. ${label}`, 45);
  }

}
