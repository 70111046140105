import { RgbColor } from './rgb-color';

export class HslColor {

  static colorConverter: any;

  constructor (public h: number, public s: number, public l: number) {
  }

  toRgb(): RgbColor {
    const rgbArray: number[] = HslColor.getColorConverter().hsl.rgb(this.h, this.s, this.l);
    return new RgbColor(rgbArray[0], rgbArray[1], rgbArray[2]);
  }

  static fromRgb(rgbColor: RgbColor): HslColor {
    const hslArray: number[] = HslColor.getColorConverter().rgb.hsl(rgbColor.r, rgbColor.g, rgbColor.b);
    return new HslColor(hslArray[0], hslArray[1], hslArray[2]);
  }

  static getColorConverter(): any {
    if (!this.colorConverter) {
      // https://www.npmjs.com/package/color-convert
      this.colorConverter = require('color-convert'); 
    }
    return this.colorConverter;
  }

}
