import { countries as typedCountries, Country as TypedCountry } from 'typed-countries';

import { QuestionEntity } from './question-entity';

export class CountryEntity {
  number: number;  
  code: string;
  name: string;
  region: string;

  static readonly EUROPEAN_COUNTRY_CODES: string[] = [
          'PT',
          'ES',
          'FR',
          'DE',
          'BE',
          'NL',
          'IT',
          'AT',
          'GB',
          'IE',
          'CH',
          'LU',
          'SE',
          'FI',
          'NO',
          'DK',
          'IS',
          'LV',
          'LT',
          'EE',
          'PL',
          'RO',
          'UA',
          'SK',
          'SI',
          'MT',
          'HU',
          'GI',
          'BY',
          'CY',
          'HR',
          'CZ',
          'MD',
          'BG',
          'BA',
          'MK',
          'MD',
          'AL',
          'RS',
          'ME',
          'GR',
          'XK',
          'AD',
          'MC',
          'LI',
          'SM',
          'VA',
          'TR',
        ];  

  static compare(a: CountryEntity, b: CountryEntity): number {
    return a.name.localeCompare(b.name);
  }

  static getCountriesFromCountryQuestion(countryQuestion: QuestionEntity): CountryEntity[] {
    const countries: CountryEntity[] = [];    
    for (const enumValue of countryQuestion.enum_values) {
      const countryName = enumValue.text;
      const typedCountry: TypedCountry = typedCountries.find(c => c.name === countryName);
      if (typedCountry) {
        countries.push({
          number: enumValue.value,
          code: typedCountry.iso,
          name: typedCountry.name,
          region: typedCountry.region,
        });
      } else {
        // 'Other country'
        countries.push({
          number: enumValue.value,
          code: 'N/A', // enumValue.text,
          name: enumValue.text,
          region: enumValue.text,
        });
      }
    }
    return countries.sort(CountryEntity.compare);
  }

}
