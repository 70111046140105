import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';

import { RawQueryResultIndicatorValuesEntity } from './raw-query-result-indicator-values-entity';

import { QuestionEntity } from '../entities/question-entity';
import { SurveyEntity } from '../entities/survey-entity';

@jsonObject
export class RawQueryResultIndicatorEntity {
  @jsonMember
  indicator_uuid: string;

  @jsonArrayMember(RawQueryResultIndicatorValuesEntity)
  values: RawQueryResultIndicatorValuesEntity[];

  indicatorQuestion: QuestionEntity;

  init(survey: SurveyEntity) {
    if (this.indicator_uuid) {
      this.indicatorQuestion = survey.getQuestionByUuid(this.indicator_uuid);
    }
  }

}
