import { Injectable } from '@angular/core';

import { Constants } from '../constants';

@Injectable({ providedIn: 'root' })
export class MessageService {
  messages: string[] = [];

  clear() {
    this.messages = [];
  }

  debug(service: string, operation: string, message: string, object?: any) : string {
    return this.formatMessage(
      service, operation, message, Constants.ENABLE_DEBUG_DETAILS ? object : undefined);
  }

  info(service: string, operation: string, message: any, object?: any) : string {
    return this.add(this.formatMessage(service, operation, message, object));
  }

  error(service: string, operation: string, message: any, object?: any) : string {
    return this.add(this.formatMessage(service, `${operation} failed`, message, object));
  }

  private formatMessage(service: string, operation: string, message: any, object?: any) : string {    
    return object ? `[${service}] ${operation}: ${message}: ${this.toJson(object)}` :
      `[${service}] ${operation}: ${message}`;
  }

  add(message: string) : string {
    this.messages.push(message);
    return message;
  }

  toJson(obj: any) {
    let stringify = require('fast-json-stable-stringify');
    return stringify(obj, (a, b) => a.key < b.key ? 1 : -1);
  }

}
