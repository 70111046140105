import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog, MatButton, MatBadge } from '@angular/material';

import { BackendApiService } from '../../services/backend-api.service';
import { MessageService } from '../../services/message.service';

import { SurveyEntity } from '../../types/entities/survey-entity';
import { QuestionEntity } from '../../types/entities/question-entity';

import { QuestionsSelection } from '../../types/ui-data/questions-selection';
import { ConstraintQuestionsSelection } from '../../types/ui-data/constraint-questions-selection';

import { QuestionsSelectorDialogComponent } from '../../components/questions-selector-dialog/questions-selector-dialog.component';
import { ConstraintQuestionsSelectorDialogComponent }
  from '../../components/constraint-questions-selector-dialog/constraint-questions-selector-dialog.component';

@Component({
  selector: 'app-heat-map-page',
  templateUrl: './heat-map-page.component.html',
  styleUrls: ['./heat-map-page.component.scss']
})
export class HeatMapPageComponent implements OnInit {

  // @ViewChild(MatButton)
  // showHeatMapButton : MatButton;

  private indicatorQuestionsSelection: QuestionsSelection;
  private constraintQuestionsSelection: ConstraintQuestionsSelection;

  private survey: SurveyEntity;
  private indicatorQuestion: QuestionEntity;
  private constraintQuestions: QuestionEntity[];
  // private showHeatMap : boolean = false;

  private numberOfIndicatorQuestions: number;
  private numberOfConstraintQuestions: number;

  constructor(
    private dialog: MatDialog,
    private overlay: Overlay,
    private backendApiService: BackendApiService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.numberOfIndicatorQuestions = 0;
    this.numberOfConstraintQuestions = 1;

    this.backendApiService.getMainSurvey()
      .subscribe(survey => {
        this.survey = survey;
        if (!this.constraintQuestions) {
          this.constraintQuestions = [survey.countryQuestion];
        }
      });
  }

  onSelectIndicatorQuestion(indicatorQuestion: QuestionEntity) {
    this.indicatorQuestion = indicatorQuestion;
    this.numberOfIndicatorQuestions = indicatorQuestion ? 1 : 0;

    console.log(this.messageService.debug(
      'HeatMapPageComponent', 'onSelectIndicatorQuestion()', 'Selected indicatorQuestion',
        this.indicatorQuestion ? this.indicatorQuestion.name : 'undefined'));
  }

  onSelectConstraintQuestions(constraintQuestions: QuestionEntity[]) {
    this.constraintQuestions = constraintQuestions;
    this.numberOfConstraintQuestions = constraintQuestions && constraintQuestions.length || 0;

    console.log(this.messageService.debug(
      'HeatMapPageComponent', 'onSelectConstraintQuestion()', 'Selected constraintQuestions', this.constraintQuestions.length));
  }

  openIndicatorQuestionSelectorDialog() {
    if (!this.indicatorQuestionsSelection) {
      const singleValueGroupRestriction: boolean = false;
      const singleQuestionRestriction: boolean = true;
      this.indicatorQuestionsSelection = new QuestionsSelection(
        this.survey, singleValueGroupRestriction, singleQuestionRestriction, null, null, null, null);
    }

    const dialogRef = this.dialog.open(QuestionsSelectorDialogComponent, {
      disableClose: true,
      minHeight: '700px',
      // minHeight: 'calc(100vh - 90px)',
      height : 'auto',
      width: '1080px',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      data: this.indicatorQuestionsSelection,
      hasBackdrop: true,
    });

    dialogRef.afterClosed().subscribe(indicatorQuestionsSelection => {
      this.indicatorQuestionsSelection = indicatorQuestionsSelection;
      this.onSelectIndicatorQuestion(
        indicatorQuestionsSelection.questions && indicatorQuestionsSelection.questions[0] || undefined);
    });
  }

  openConstraintQuestionsSelectorDialog() {
    if (!this.constraintQuestionsSelection) {
      this.constraintQuestionsSelection = new ConstraintQuestionsSelection(this.survey, this.constraintQuestions);
    }

    const dialogRef = this.dialog.open(ConstraintQuestionsSelectorDialogComponent, {
      disableClose: true,
      minHeight: '300px',
      width: '1080px',
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      data: this.constraintQuestionsSelection,
      hasBackdrop: true,
    });

    dialogRef.afterClosed().subscribe(constraintQuestionsSelection => {
      this.constraintQuestionsSelection = constraintQuestionsSelection;
      this.onSelectConstraintQuestions(
        constraintQuestionsSelection && constraintQuestionsSelection.questions || []);
    });
  }



}
