import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { FormGroup, FormControl, Validators  } from '@angular/forms';

import { CountryEntity } from '../../types/entities/country-entity';
import { QuestionEntity } from '../../types/entities/question-entity';
import { SurveyEntity } from '../../types/entities/survey-entity';

import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-user-survey',
  templateUrl: './user-survey.component.html',
  styleUrls: ['./user-survey.component.scss']
})
export class UserSurveyComponent implements OnInit {

  @Input()
  survey: SurveyEntity;

  @Output()
  submitAnswers = new EventEmitter<number[]>();


  private questions: QuestionEntity[];
  private answers = [];
  private surveyFormGroup: FormGroup;
  private surveySubmitted: boolean;

  constructor(private messageService: MessageService) {
  }

  ngOnInit() {
    this.questions = this.survey.questions;
    this.answers = new Array<number>(this.questions.length);

    const groupConfig: any = {};
    this.questions.forEach(question => {
      groupConfig[question.name] = new FormControl(null, Validators.required);
    });
    
    console.log(this.messageService.debug(
      'UserSurveyComponent', 'ngOnInit()', 'groupConfig', groupConfig));
    
    this.surveyFormGroup = new FormGroup(groupConfig);
  }

  onSubmit(event: any): void {    
    this.surveyFormGroup.disable();
    this.surveySubmitted = true;
    const answers: number[] = this.questions.map(question => {
      const value: any = this.surveyFormGroup.controls[question.name].value;
      console.log(this.messageService.debug(
        'UserSurveyComponent', 'onSubmit()', `question: ${question.name}, answer: ${value}`));
      return parseInt(value);
    });

    this.submitAnswers.emit(answers);
  }

}
