import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-data-message',
  templateUrl: './loading-data-message.component.html',
  styleUrls: ['./loading-data-message.component.scss']
})
export class LoadingDataMessageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
