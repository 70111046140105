import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BackendApiService } from '../../services/backend-api.service';

import { SurveyEntity } from '../../types/entities/survey-entity';
import { CategoryEntity } from '../../types/entities/category-entity';
import { TagEntity } from '../../types/entities/tag-entity';
import { ValueGroupEntity } from '../../types/entities/value-group-entity';
import { QuestionEntity } from '../../types/entities/question-entity';


@Component({
  selector: 'app-question-list-page',
  templateUrl: './question-list-page.component.html',
  styleUrls: ['./question-list-page.component.scss']
})
export class QuestionListPageComponent implements OnInit {

  private survey: SurveyEntity;
  private visibleCategories: CategoryEntity[];
  private visibleTags: TagEntity[];
  private likertOnly: boolean;

  constructor(
    private backendApiService: BackendApiService,
    private route: ActivatedRoute) {
      this.route.queryParams.subscribe(params => {
        const likertOnlyParam: string = params['likertOnly'];
        this.likertOnly = !likertOnlyParam || likertOnlyParam.toLowerCase() === 'true';
      });
  }

  ngOnInit() {
    this.backendApiService
      .getMainSurvey()
      .subscribe(survey => {
        this.survey = survey;
        this.visibleCategories = survey.getActiveCategories().sort(CategoryEntity.compare);
        this.visibleTags = survey.getActiveTags().sort(TagEntity.compare);
      });
  }

  private getVisibleQuestions(category: CategoryEntity): QuestionEntity[] {
    return this.survey.questions
            .filter(q => q.category && q.category === category &&
                  (!this.likertOnly || q.value_group && q.value_group.is_likert_compatible))
            .sort(QuestionEntity.compareByShortLabel);            
  }

  private getEnumValuesText(question: QuestionEntity) {
    return question.getEnumValuesText();
  }

  // private getVisibleTags(questions: QuestionEntity[]): TagEntity[] {
  //   const visibleTagSet: Set<TagEntity> = new Set();

  //   for (const question of questions) {
  //     if (question.tags) {
  //       for (const tag of question.tags) {
  //         visibleTagSet.add(tag);
  //       }
  //     }
  //   }

  //   return Array.from(visibleTagSet).sort(TagEntity.compare);
  // }

}
