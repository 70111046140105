import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson';

import { EchoesUtils } from '../../utils/echoes-utils';

import { SurveyEntity } from './survey-entity';
import { CategoryEntity } from './category-entity';
import { TagEntity } from './tag-entity';
import { ValueGroupEntity } from './value-group-entity';
import { QuestionPropertyEntity } from './question-property-entity';
import { QuestionEnumValueEntity } from './question-enum-value-entity';

@jsonObject
export class QuestionEntity {

  static readonly COUNTRY_QUESTION_NAME: string = 'country_sample';
  static readonly MAIN_CONSTRAINT_QUESTION_KIND: string = 'Main Constraint';
  static readonly CONSTRAINT_QUESTION_KIND: string = 'Constraint';

  static compareByLabel(a : QuestionEntity, b : QuestionEntity) : number {
    return EchoesUtils.String.compare(a.label, b.label);
  }
    
  static compareByShortLabel(a : QuestionEntity, b : QuestionEntity) : number {
    return EchoesUtils.String.compare(a.short_label || a.label, b.short_label || b.label);
  }

  static compareByName(a : QuestionEntity, b : QuestionEntity) : number {
    return EchoesUtils.String.compare(a.name, b.name);
  }


  @jsonMember
  uuid: string;

  @jsonMember
  name: string;

  @jsonMember
  short_label: string;

  @jsonMember
  label: string;

  @jsonMember
  order_number: number;

  @jsonMember
  kind: string;

  @jsonMember
  is_collective: string;

  @jsonMember
  category_uuid: string;

  // @jsonMember
  category: CategoryEntity;

  @jsonMember
  type: string;

  @jsonMember
  value_group_uuid: string;

  value_group: ValueGroupEntity;

  @jsonArrayMember(String)
  tags_uuids: string[];

  tags: TagEntity[];

  @jsonArrayMember(QuestionPropertyEntity)
  properties: QuestionPropertyEntity[];

  @jsonArrayMember(QuestionEnumValueEntity)
  enum_values: QuestionEnumValueEntity[];

  init(survey: SurveyEntity): QuestionEntity {
    if (this.category_uuid) {
      this.category = survey.categories.find(c => c.uuid === this.category_uuid);
    }

    this.tags = [];
    if (this.tags_uuids) {
      for (const tag_uuid of this.tags_uuids) {
        const tag: TagEntity = survey.tags.find(t => t.uuid === tag_uuid);
        this.tags.push(tag);
      }
    }

    if (this.value_group_uuid) {
      this.value_group = survey.value_groups.find(v => v.uuid === this.value_group_uuid);
    }

    if (this.enum_values) {
      this.enum_values = this.enum_values.sort(QuestionEnumValueEntity.compare);
    }

    return this;
  }

  isCountryQuestion(): boolean {
    return this.name === QuestionEntity.COUNTRY_QUESTION_NAME;
  }

  isConstraintQuestion(): boolean {
    return this.kind === QuestionEntity.MAIN_CONSTRAINT_QUESTION_KIND ||
          this.kind === QuestionEntity.CONSTRAINT_QUESTION_KIND;
  }

  getShortLabel(maxLength?: number): string {
    return EchoesUtils.String.formatShortString(this.short_label || this.label, maxLength);
  }

  getLabel(maxLength?: number): string {
    return EchoesUtils.String.formatShortString(this.label, maxLength);
  }

  getShortLabelWithName(maxLength?: number): string {
    // return (this.name ? `${this.name}: ` : '') + this.getShortLabel(maxLength);
    return this.getShortLabel(maxLength) + ` (${this.name})`;
  }

  getLabelWithName(maxLength?: number): string {
    // return (this.name ? `${this.name}: ` : '') + this.getLabel(maxLength);
    return this.getLabel(maxLength) + ` (${this.name})`;
  }

  getMinEnumValue() : number {
    return this.enum_values ? this.enum_values[0].value : undefined;
  }

  getMaxEnumValue() : number {
    return this.enum_values ? this.enum_values[this.enum_values.length - 1].value : undefined;
  }

  /**
   * Format a string like '1', '1..3', '1..5,8', '1..3,5..7'
   */
  getEnumValueRangeText(): string {
    if (!this.enum_values) {
      return undefined;
    }

    let previousValue: number = undefined;
    let lastWrittenValue: number = undefined;
    let text: string = '';

    for (const enum_value of this.enum_values) {
      const currentValue: number = + enum_value.value;
      if (!previousValue) {
        previousValue = currentValue;
        if (text !== '') {
          text += ',';
        }
        text += previousValue;
        lastWrittenValue = previousValue;
      } else {
        if (currentValue !== previousValue + 1) {
          text += '..' + previousValue + ',' + currentValue;
          lastWrittenValue = currentValue;
        }
        previousValue = currentValue; // update state
      }
    }

    if (previousValue !== lastWrittenValue) {
      text += '..' + previousValue;
    }

    return text;
  }

  getEnumValuesText(): string {
    return this.enum_values ? this.enum_values.map(ev => `${ev.value}: ${ev.text}`).join('\n') : undefined;
  }


}
 