import { QueryDataRecordEntity } from './query-data-record-entity';
import { RawQueryResultEntity } from './raw-query-result-entity';
// import { QuestionEntity } from '../entities/question-entity';
// import { CountryEntity } from '../entities/country-entity';

export class QueryDataEntity {
  // countryQuestion: QuestionEntity;
  // constraintQuestions: QuestionEntity[]
  // variableQuestions: QuestionEntity[];

  // countries: CountryEntity[];
  minValue: number;
  maxValue: number;
  totalCount: number;

  rawQueryResult: RawQueryResultEntity;

  records: QueryDataRecordEntity[];
}
