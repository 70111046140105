import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import { QueriesSelectorComponent } from './pages/queries-selector/queries-selector.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { DataSourcesPageComponent } from './pages/data-sources-page/data-sources-page.component';
import { DataToolsPageComponent } from './pages/data-tools-page/data-tools-page.component';
import { DecisionMakingPageComponent } from './pages/decision-making-page/decision-making-page.component';
import { DownloadComponent } from './components/download/download.component';
import { DownloadPageComponent } from './pages/download-page/download-page.component';
import { KpiPageComponent } from './pages/kpi-page/kpi-page.component';
import { HeatMapPageComponent } from './pages/heat-map-page/heat-map-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { MillennialsPageComponent } from './pages/millennials-page/millennials-page.component';
import { QuestionListPageComponent } from './pages/question-list-page/question-list-page.component';
import { RawDataPageComponent } from './pages/raw-data-page/raw-data-page.component';
import { RawDataStatisticsPageComponent } from './pages/raw-data-statistics-page/raw-data-statistics-page.component';
import { SpiderWebChartPageComponent } from './pages/spider-web-chart-page/spider-web-chart-page.component';

// for test purpose only
import { TestPageComponent } from './pages/test-page/test-page.component';

const ROUTES: Routes = [
  { path: '', redirectTo: '/home', pathMatch:'full' },
  { path: 'home', component: HomePageComponent },
  {
    path: 'data-tools',
    children: [
      { path: '', component: DataToolsPageComponent },
      { path: 'decision-making', component: DecisionMakingPageComponent },
      { path: 'heatmap', component: HeatMapPageComponent },
      { path: 'kpi', component: KpiPageComponent },
      { path: 'millennials', component: MillennialsPageComponent },
      { path: 'spider', component: SpiderWebChartPageComponent },
    ]
  },
  { path: 'data-sources', component: DataSourcesPageComponent },
  { path: 'about', component: AboutPageComponent },
  { path: 'raw-data', component: RawDataPageComponent },
  { path: 'support', component: ContactPageComponent },
  { path: 'contact', component: ContactPageComponent },
  { path: 'download', component: DownloadPageComponent },  
  { path: 'question-list', component: QuestionListPageComponent },  

  { path: 'idownload', component: DownloadComponent },  
  { path: 'idownload-statistics', component: RawDataStatisticsPageComponent },  
  { path: 'download-statistics', component: RawDataStatisticsPageComponent },  

  // for test purpose only
  { path: 'test', component: TestPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
