import { jsonObject, jsonMember } from 'typedjson';

@jsonObject
export class QuestionEnumValueEntity {
    @jsonMember
    value: number;

    @jsonMember
    text: string;

    static compare(a: QuestionEnumValueEntity, b: QuestionEnumValueEntity): number {
        return (a.value < b.value) ? -1 :
              (a.value > b.value) ? 1 : 0;
    }
}