import { CountryEntity } from '../entities/country-entity';
import { SurveyEntity } from '../entities/survey-entity';

export class CountriesSelection {
  constructor(
    public survey: SurveyEntity,
    public countries: CountryEntity[]
  ) {}

  clone(): CountriesSelection {
    return new CountriesSelection(
      this.survey,
      this.countries ? [...this.countries] : undefined);
  }
}
