import { Component, EventEmitter, OnInit, Input, Output, ViewChild } from '@angular/core';

import { EchoesUtils } from '../../utils/echoes-utils';

import { MessageService } from '../../services/message.service';

import { SurveyEntity } from '../../types/entities/survey-entity';
import { CategoryEntity } from '../../types/entities/category-entity';
import { ValueGroupEntity } from '../../types/entities/value-group-entity';
import { TagEntity } from '../../types/entities/tag-entity';
import { QuestionEntity } from '../../types/entities/question-entity';

import { NormalizedQuestion } from '../../types/ui-data/normalized-question';

import { QuestionsSelection } from '../../types/ui-data/questions-selection';

import { MatSelectChange } from '@angular/material/select';
import { MatSelectionList, MatSelectionListChange, MatListOption } from '@angular/material/list';


@Component({
  selector: 'app-questions-selector',
  templateUrl: './questions-selector.component.html',
  styleUrls: ['./questions-selector.component.scss']
})
export class QuestionsSelectorComponent implements OnInit {

  @Input()
  survey: SurveyEntity;

  @Input()
  singleQuestionRestriction: boolean;

  @Input()
  singleValueGroupRestriction: boolean;

  @Input()
  questionsSelection: QuestionsSelection;

  @Output()
  selectionChange = new EventEmitter<QuestionsSelection>();

  // @Input()
  // maxSelectedQuestionsLength: number = 5;

  // private readonly categoriesDropdownlistSettings = {
  //       singleSelection: true,
  //       primaryKey: 'uuid',
  //       labelKey: 'name',
  //       text:'Select A Category',
  //       enableSearchFilter: true,
  //       classes: ''
  //     };

  private readonly categoriesDropdownlistSettings = {
        singleSelection: false,
        primaryKey: 'uuid',
        labelKey: 'name',
        text: 'Select Categories',
        enableSearchFilter: false,
        classes: 'width-98-percentage',
      };

  private readonly tagsDropdownlistSettings = {
        singleSelection: false,
        primaryKey: 'uuid',
        labelKey: 'name',
        text: 'Select Tags',
        enableSearchFilter: true,
        classes: 'width-98-percentage',
      };

  private readonly valueGroupsDropdownlistSettings = {
        singleSelection: false,
        primaryKey: 'uuid',
        labelKey: 'label',
        text: undefined, // defined in ngOnInit()
        enableSearchFilter: false,
        classes: 'width-98-percentage',
      };


  private visibleCategories: CategoryEntity[];
  private selectedCategories: CategoryEntity[];
  private selectedValueGroups: ValueGroupEntity[];

  private allTags: TagEntity[];
  private visibleTags: TagEntity[];
  private selectedTags: TagEntity[];

  private allValueGroups: ValueGroupEntity[];
  private visibleValueGroups: ValueGroupEntity[];

  // private allQuestionHierarchies: QuestionHierarchy[];
  // private visibleQuestionHierarchies: QuestionHierarchy[];
  private allQuestions: QuestionEntity[];
  private visibleQuestions: QuestionEntity[];

  private selectedQuestions: QuestionEntity[];
  // private selectedQuestion: QuestionEntity;

  private visibleNormalizedQuestions: NormalizedQuestion[];
  private selectedNormalizedQuestion: NormalizedQuestion;

  @ViewChild(MatSelectionList, {static: false})
  multipleQuestionsSelectorCtrl: MatSelectionList;

  constructor(private messageService: MessageService) { }

  ngOnInit() {

    if (!this.survey) {
      throw new Error('survey is undefined');
    }

    this.valueGroupsDropdownlistSettings.singleSelection = !!this.singleValueGroupRestriction;
    this.valueGroupsDropdownlistSettings.text = this.valueGroupsDropdownlistSettings.singleSelection ?
           'Select A Value Format' : 'Select Value Formats';

    this.allQuestions = this.survey.questions || [];
    this.allTags = this.survey.getActiveTags() || [];
    this.allValueGroups = this.survey.getLikertValueGroups() || [];

    // this.visibleCategories = [];
    // this.visibleTags = [];
    // this.visibleValueGroups = [];
    // this.visibleQuestions = [];
    // this.visibleNormalizedQuestions = [];

    this.updateVisibleCategories();

    if (this.visibleCategories) {
      if (this.questionsSelection) {
        console.log(this.messageService.debug(
          'QuestionsSelectorComponent', 'ngOnInit()', '!!this.questionsSelection', !!this.questionsSelection));

        this.selectedCategories = this.questionsSelection.categories;
        this.selectedTags = this.questionsSelection.tags;
        this.selectedValueGroups = this.questionsSelection.valueGroups;
        this.selectedQuestions = this.questionsSelection.questions;
        this.selectedNormalizedQuestion = this.questionsSelection.normalizedQuestion;
      }

      if (this.visibleCategories.length === 1) {
        this.selectedCategories = this.visibleCategories;
      }

      if (this.selectedCategories) {
        this.onSelectCategories();
      }
    }
  }

  // ngAfterContentInit() {
  //   // if (this.selectedQuestions) {
  //   //   console.error(this.messageService.debug(
  //   //     'QuestionsSelectorComponent', 'ngAfterContentInit()', 'this.visibleQuestionHierarchies.length', this.visibleQuestionHierarchies.length));
  //   //   console.error(this.messageService.debug(
  //   //     'QuestionsSelectorComponent', 'ngAfterContentInit()', 'this.multipleQuestionsSelector', this.multipleQuestionsSelectorCtrl));
  //   // }
  // }

  // ngAfterViewInit() {
  //   // if (this.selectedQuestions) {
  //   //   console.error(this.messageService.debug(
  //   //     'QuestionsSelectorComponent', 'ngAfterViewInit()', 'this.visibleQuestionHierarchies.length', this.visibleQuestionHierarchies.length));
  //   //   console.error(this.messageService.debug(
  //   //     'QuestionsSelectorComponent', 'ngAfterViewInit()', 'this.multipleQuestionsSelector', this.multipleQuestionsSelectorCtrl));
  //   // }
  // }

  private updateVisibleCategories(): void {
    const visibleCategorySet: Set<CategoryEntity> = new Set();

    for (const question of this.allQuestions) {
      if (!question.isConstraintQuestion() && question.category && CategoryEntity.isActive(question.category)) {
        visibleCategorySet.add(question.category);
      }
    }

    this.visibleCategories = Array.from(visibleCategorySet).sort(CategoryEntity.compare);

    console.log(this.messageService.debug(
      'QuestionsSelectorComponent', 'updateVisibleCategories()', 'this.visibleCategories.length',
        EchoesUtils.Array.getLength(this.visibleCategories)));
  }

  private onSelectCategories(): void {
    console.log(this.messageService.debug(
      'QuestionsSelectorComponent', 'onSelectCategories()', 'this.selectedCategories.length',
        EchoesUtils.Array.getLength(this.selectedCategories)));

    if (this.selectedCategories && this.selectedCategories.length > 0) {
      this.updateVisibleTags();
      this.onSelectTags();
    } else {
      this.visibleTags = [];
      this.visibleValueGroups = [];
      this.visibleQuestions = [];
    }
  }

  private updateVisibleTags(): void {
    const visibleTagSet: Set<TagEntity> = new Set();
    this.updateVisibleQuestions(this.selectedCategories, null, null);

    console.log(this.messageService.debug(
      'QuestionsSelectorComponent', 'updateVisibleTags()', 'this.visibleQuestions.length',
        EchoesUtils.Array.getLength(this.visibleQuestions)));

    for (const question of this.visibleQuestions) {
      if (question.tags) {
        for (const tag of question.tags) {
          visibleTagSet.add(tag);
        }
      }
    }

    // this.visibleTags = Array.from(visibleTagSet).sort(TagEntity.compare);
    this.visibleTags = Array.from(visibleTagSet).sort(TagEntity.compare);

    console.log(this.messageService.debug(
      'QuestionsSelectorComponent', 'updateVisibleTags()', 'this.visibleTags.length',
        EchoesUtils.Array.getLength(this.visibleTags)));
  }

  private onSelectTags(): void {
    console.log(this.messageService.debug(
      'QuestionsSelectorComponent', 'onSelectTags()', 'this.allTags.length',
        EchoesUtils.Array.getLength(this.allTags)));

    console.log(this.messageService.debug(
      'QuestionsSelectorComponent', 'onSelectTags()', 'this.selectedTags.length',
        EchoesUtils.Array.getLength(this.selectedTags)));

    if (this.allTags.length === 0 || this.selectedTags && this.selectedTags.length > 0) {
      this.updateVisibleValueGroups();
      this.onSelectValueGroups();
    } else {
      this.visibleValueGroups = undefined;
      this.visibleQuestions = undefined;
    }

    console.log(this.messageService.debug(
      'QuestionsSelectorComponent', 'onSelectTags()', 'this.visibleValueGroups.length',
        EchoesUtils.Array.getLength(this.visibleValueGroups)));
  }

  private updateVisibleValueGroups(): void {
    const visibleValueGroupSet: Set<ValueGroupEntity> = new Set();
    this.updateVisibleQuestions(this.selectedCategories, this.selectedTags, null);

    for (const question of this.visibleQuestions) {
      if (question.value_group) {
        visibleValueGroupSet.add(question.value_group);
      }
    }

    this.visibleValueGroups = Array.from(visibleValueGroupSet).sort(ValueGroupEntity.compare);

    console.log(this.messageService.debug(
      'QuestionsSelectorComponent', 'updateVisibleValueGroups()', 'this.visibleValueGroups.length',
        EchoesUtils.Array.getLength(this.visibleValueGroups)));
  }

  private onSelectValueGroups(): void {
    console.log(this.messageService.debug(
      'QuestionsSelectorComponent', 'onSelectValueGroups()', 'this.selectedValueGroups.length',
        EchoesUtils.Array.getLength(this.selectedValueGroups)));

    if (this.selectedValueGroups && this.selectedValueGroups.length > 0) {
      this.updateVisibleQuestions(this.selectedCategories, this.selectedTags, this.selectedValueGroups);
    } else {
      this.visibleQuestions = undefined;
    }
    this.updateVisibleNormalizedQuestions();
  }

  private updateVisibleQuestions(
    selectedCategories: CategoryEntity[],
    selectedTags: TagEntity[],
    selectedValueGroups: ValueGroupEntity[]
  ): void {

    console.log(this.messageService.debug(
      'QuestionsSelectorComponent', 'updateVisibleQuestions()', 'Input: selectedCategories.length',
        EchoesUtils.Array.getLength(selectedCategories)));
      
    if (selectedTags) {
      console.log(this.messageService.debug(
        'QuestionsSelectorComponent', 'updateVisibleQuestions()', 'Input: selectedTags.length',
          EchoesUtils.Array.getLength(selectedTags)));
    }

    if (selectedValueGroups) {
      console.log(this.messageService.debug(
        'QuestionsSelectorComponent', 'updateVisibleQuestions()', 'Input: selectedValueGroups.length',
          EchoesUtils.Array.getLength(selectedValueGroups)));
    }

    if (this.allQuestions) {
      console.log(this.messageService.debug(
        'QuestionsSelectorComponent', 'updateVisibleQuestions()', 'Input: this.allQuestions.length',
          EchoesUtils.Array.getLength(this.allQuestions)));
    }

    this.visibleQuestions =
      this.allQuestions
        .filter(q =>
          !q.isConstraintQuestion() &&
          (!selectedCategories || EchoesUtils.Array.includes(selectedCategories, q.category)) &&
          (this.allTags.length === 0 || !selectedTags || EchoesUtils.Array.includesAny(selectedTags, q.tags)) &&
          (this.allValueGroups.length === 0 ||
            (q.value_group && q.value_group.is_likert_compatible && (!selectedValueGroups || EchoesUtils.Array.includes(selectedValueGroups, q.value_group))))
        );

    console.log(this.messageService.debug(
      'QuestionsSelectorComponent', 'updateVisibleQuestions()', 'this.visibleQuestions.length', 
        EchoesUtils.Array.getLength(this.visibleQuestions)));
  }

  private getQuestionsOfCategoryAndTag(category: CategoryEntity, tag: TagEntity): QuestionEntity[] {
    if (!this.visibleQuestions) {
      return undefined;
    }

    let questions: QuestionEntity[] = this.visibleQuestions;

    if (category) {
      questions = questions.filter(q => q.category === category);
    }

    if (tag) {
      questions = questions.filter(q => q.tags && q.tags.includes(tag));
    }

    return questions.sort(QuestionEntity.compareByShortLabel);
  }

  onSelectQuestions(event?: MatSelectionListChange): void {
    if (!this.selectedQuestions) {
      this.selectedQuestions = [];
    }

    if (!this.multipleQuestionsSelectorCtrl) {
      return;
    }

    if (event) {
      // get options with the same value as in the event
      const options: MatListOption[] =
        this.multipleQuestionsSelectorCtrl.options
          .filter(option => option.value === event.option.value);

      for (const option of options) {
        option.selected = event.option.selected;
      }

      if (event.option.selected) {
        this.selectedQuestions.push(event.option.value);
      } else {
        this.selectedQuestions = this.selectedQuestions.filter(question => question !== event.option.value);
      }
    } else {
      this.multipleQuestionsSelectorCtrl.options.forEach(option => {
        option.selected = this.selectedQuestions.includes(option.value);
      });
    }

    const questionsSelection = new QuestionsSelection(
      this.survey,
      this.singleValueGroupRestriction,
      this.singleQuestionRestriction,
      this.selectedCategories,
      this.selectedTags,
      this.selectedValueGroups,
      this.selectedQuestions
    );

    console.log(this.messageService.debug(
      'QuestionsSelectorComponent', 'onSelectQuestions()', `Emitting event: ${questionsSelection.questions.length} questions`));

    this.selectionChange.emit(questionsSelection);
  }

  onSelectQuestion(event: any): void {
    console.log(this.messageService.debug(
      'QuestionsSelectorComponent', 'onSelectQuestion()', '!!this.selectedNormalizedQuestion',
        !!this.selectedNormalizedQuestion));

    const questionsSelection = new QuestionsSelection(
      this.survey,
      this.singleValueGroupRestriction,
      this.singleQuestionRestriction,
      this.selectedCategories,
      this.selectedTags,
      this.selectedValueGroups,
      this.selectedNormalizedQuestion ? [this.selectedNormalizedQuestion.question] : [],
      this.selectedNormalizedQuestion
    );

    console.log(this.messageService.debug(
      'QuestionsSelectorComponent', 'onSelectQuestion()', `Emitting event: ${questionsSelection.questions.length} questions`));

    this.selectionChange.emit(questionsSelection);
  }

  private getAllNonConstraintQuestions(): QuestionEntity[] {
    return this.allQuestions.filter(q => !q.isConstraintQuestion()).sort(QuestionEntity.compareByShortLabel);
  }

  private updateVisibleNormalizedQuestions(): void {
    this.visibleNormalizedQuestions =
      NormalizedQuestion.toNormalizedQuestions(this.visibleQuestions, this.selectedTags);

    console.log(this.messageService.debug(
      'QuestionsSelectorComponent', 'updateVisibleNormalizedQuestions()', 'this.visibleNormalizedQuestions.length',
        EchoesUtils.Array.getLength(this.visibleNormalizedQuestions)));
  }

}
