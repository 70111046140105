import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { APP_BASE_HREF, PlatformLocation } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';

// https://material.angular.io/
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule, MatCheckboxModule } from '@angular/material';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';

// https://ng-bootstrap.github.io/#/getting-started
// https://www.npmjs.com/package/@ng-bootstrap/ng-bootstrap
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


// http://cuppalabs.github.io/components/multiselectDropdown
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

// https://www.npmjs.com/package/@ng-select/ng-select
// https://ng-select.github.io/ng-select
import { NgSelectModule } from '@ng-select/ng-select';

// https://www.npmjs.com/package/@ng-select/ng-option-highlight
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';

// // https://www.npmjs.com/package/ngx-avatar
// import { AvatarModule } from 'ngx-avatar';

// https://www.npmjs.com/package/ngx-lightbox
// Note: Version ngx-lightbox@1.2.0 is being used
import { LightboxModule } from 'ngx-lightbox';

// https://www.npmjs.com/package/angular2-cookie-law
// import { CookieLawModule } from 'angular2-cookie-law';

// https://www.npmjs.com/package/angular2-hotkeys
import { HotkeyModule } from 'angular2-hotkeys';

/**
 * Echoes modules
 */

/**
 * Echoes pages
 */
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { DataSourcesPageComponent } from './pages/data-sources-page/data-sources-page.component';
import { DecisionMakingPageComponent } from './pages/decision-making-page/decision-making-page.component';
import { DownloadPageComponent } from './pages/download-page/download-page.component';
import { KpiPageComponent } from './pages/kpi-page/kpi-page.component';
import { HeatMapPageComponent } from './pages/heat-map-page/heat-map-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { MillennialsPageComponent } from './pages/millennials-page/millennials-page.component';
import { RawDataPageComponent } from './pages/raw-data-page/raw-data-page.component';
import { SpiderWebChartPageComponent } from './pages/spider-web-chart-page/spider-web-chart-page.component';
import { TestPageComponent } from './pages/test-page/test-page.component';

/**
 * Echoes components
 */
import { ConstraintQuestionsSelectorComponent } from './components/constraint-questions-selector/constraint-questions-selector.component';
import { ConstraintQuestionsSelectorDialogComponent }
  from './components/constraint-questions-selector-dialog/constraint-questions-selector-dialog.component';
import { CountriesSelectorComponent } from './components/countries-selector/countries-selector.component';
import { CountriesSelectorDialogComponent } from './components/countries-selector-dialog/countries-selector-dialog.component';
import { DataToolsPageComponent } from './pages/data-tools-page/data-tools-page.component';
import { DownloadComponent } from './components/download/download.component';
import { DownloadQueryDataComponent } from './components/download-query-data/download-query-data.component';
import { HeatMapComponent } from './components/heat-map/heat-map.component';
import { LoadingDataMessageComponent } from './components/loading-data-message/loading-data-message.component';
import { QuestionsSelectorComponent } from './components/questions-selector/questions-selector.component';
import { QuestionsSelectorDialogComponent } from './components/questions-selector-dialog/questions-selector-dialog.component';
import { SpiderWebChartComponent } from './components/spider-web-chart/spider-web-chart.component';
import { UserSurveyComponent } from './components/user-survey/user-survey.component';
import { QuestionListPageComponent } from './pages/question-list-page/question-list-page.component';
import { RawDataStatisticsPageComponent } from './pages/raw-data-statistics-page/raw-data-statistics-page.component';
import { RawDataDownloadChartComponent } from './components/raw-data-download-chart/raw-data-download-chart.component';

// /**
//  * This function is used internal to get a string instance of the `<base href="" />` value from `index.html`.
//  * This is an exported function, instead of a private function or inline lambda, to prevent this error:
//  *
//  * `Error encountered resolving symbol values statically.`
//  * `Function calls are not supported.`
//  * `Consider replacing the function or lambda with a reference to an exported function.`
//  *
//  * @param platformLocation an Angular service used to interact with a browser's URL
//  * @return a string instance of the `<base href="" />` value from `index.html`
//  * Source: https://stackoverflow.com/questions/39287444/angular2-how-to-get-app-base-href-programmatically/39287458#39287458
//  */
// export function getBaseHref(platformLocation: PlatformLocation): string {
//     return platformLocation.getBaseHrefFromDOM();
// }

@NgModule({
  declarations: [
    AppComponent,
    HeatMapComponent,
    HeatMapPageComponent,
    SpiderWebChartComponent,
    CountriesSelectorComponent,
    HomePageComponent,
    SpiderWebChartPageComponent,
    DataToolsPageComponent,
    CountriesSelectorDialogComponent,
    QuestionsSelectorComponent,
    QuestionsSelectorDialogComponent,
    ConstraintQuestionsSelectorComponent,
    ConstraintQuestionsSelectorDialogComponent,
    DecisionMakingPageComponent,
    TestPageComponent,
    DownloadQueryDataComponent,
    MillennialsPageComponent,
    KpiPageComponent,
    AboutPageComponent,
    DataSourcesPageComponent,
    ContactPageComponent,
    RawDataPageComponent,
    DownloadPageComponent,
    DownloadComponent,
    UserSurveyComponent,
    LoadingDataMessageComponent,
    QuestionListPageComponent,
    RawDataStatisticsPageComponent,
    RawDataDownloadChartComponent,
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,

    FormsModule,
    ReactiveFormsModule,

    DragDropModule,
    OverlayModule,

    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatRadioModule,
    MatSelectModule,
    MatStepperModule,
    MatTooltipModule,

    AngularMultiSelectModule,
    NgSelectModule,
    NgOptionHighlightModule,

    // AvatarModule,
    LightboxModule,
    // CookieLawModule,
    HotkeyModule.forRoot(),
  ],

  entryComponents: [
    QuestionsSelectorDialogComponent,
    CountriesSelectorDialogComponent,
    ConstraintQuestionsSelectorDialogComponent,
  ],

  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    // {
    //   provide: APP_BASE_HREF,
    //   useFactory: getBaseHref,
    //   deps: [PlatformLocation]
    // },
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
