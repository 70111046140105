import { jsonObject, jsonArrayMember } from 'typedjson';

import { RawQueryResultIndicatorEntity } from './raw-query-result-indicator-entity';

import { QuestionEntity } from '../entities/question-entity';
import { SurveyEntity } from '../entities/survey-entity';
import { CountryEntity } from '../entities/country-entity';

@jsonObject
export class RawQueryResultEntity {
  @jsonArrayMember(String)
  constraint_uuids: string[];

  @jsonArrayMember(RawQueryResultIndicatorEntity)
  indicators: RawQueryResultIndicatorEntity[];

  constraintQuestions: QuestionEntity[];
  countryQuestionIndex: number;
  countries: CountryEntity[];

  init(survey: SurveyEntity): RawQueryResultEntity {

    // console.log(this.messageService.debug(
    //   'RawQueryResultEntity', 'init()', 'rawQueryResult', rawQueryResult));

    //
    // set this.constraintQuestions
    //
    if (this.constraint_uuids) {
      this.constraintQuestions = this.constraint_uuids.map(uuid => survey.getQuestionByUuid(uuid));
    } else {
      throw new Error(`Undefined this.constraint_uuids: ('${this}')`);
    }

    // 
    // set this.countryIndex
    // 
    const countryQuestionIndex = this.constraintQuestions.findIndex(question => question.isCountryQuestion());
    if (countryQuestionIndex > -1) {
      this.countryQuestionIndex = countryQuestionIndex;
      // const countryQuestion = this.constraintQuestions[index];
      // this.countries = this.countryService.getCountriesFromQuestionEnumValues(this.countryQuestion);
    } else {
      throw new Error(`Undefined this.countryQuestionIndex: ('${this}')`);
    }

    const countryNumberSet = new Set<number>();

    //
    // init this.indicators
    // 
    if (this.indicators) {
      for (const indicator of this.indicators) {
        indicator.init(survey);
        if (indicator.values) {
          for (const indicatorValue of indicator.values) {
            const countryNumber: number = indicatorValue.constraints[this.countryQuestionIndex];
            countryNumberSet.add(countryNumber);
          }
        }
      }
    } else {
      // no data is found
      this.indicators = [];
      // throw new Error(`Undefined this.indicators: ('${this}')`);
    }

    //
    // extract countries
    //
    this.countries =
      Array
        .from(countryNumberSet)
        .map(countryNumber => survey.countries.find(c => c.number === countryNumber));

    return this;
  }

}
