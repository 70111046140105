import { Injectable } from '@angular/core';

import { ValueDistribution } from '../types/queries/value-distribution';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  constructor() { }

  getAverage(values: number[]): number {
    const sum: number = values.reduce((accumulator, current) => accumulator + current);
    return sum / values.length;
  }

  // valueCounts is an array of pairs (value, count)
  getAverageOfValueCountPairs(valueCounts: number[][]): number {
    let sum: number = 0;
    let count: number = 0;
    for (const valueCount of valueCounts) {
      sum += valueCount[0] * valueCount[1];
      count += valueCount[1];
    }
    return sum / count;
  }

  // valueCounts is an array of pairs (value, count)
  getAverageOfValueDistribution(valueDistribution: ValueDistribution): number {
    // console.error(valueDistribution.toString());
    return this.getAverageOfValueCountPairs(valueDistribution.valueCounts);
 }

  getMedian(values: number[], sorted: boolean = false): number {
    // sort and return the average value of the two middle items.
    //const sortedValues: number[] = sorted ? values : Object.assign([], values).sort((a, b) => a - b);
    const sortedValues: number[] = sorted ? values : [...values].sort((a, b) => a - b);
    return (sortedValues[(sortedValues.length - 1) >> 1] + sortedValues[sortedValues.length >> 1]) / 2;
  }
}
