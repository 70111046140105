import { jsonObject, jsonMember } from 'typedjson';

@jsonObject
export class TagEntity {

  @jsonMember
  uuid: string;

  @jsonMember
  name: string;

  static compare(a : TagEntity, b : TagEntity) : number {
    return a.name.localeCompare(b.name);
  }

}