export class EchoesUtils {

  /**
   * String class
   */
  static String = class {
    static formatShortString(s: string, maxLength?: number) {
      if (!s || !maxLength || s.length < maxLength) {
        return s;
      }

      const headLength: number = maxLength * 2 / 3;
      const tailLength: number = maxLength - headLength - 3;
      return `${s.substring(0, headLength)}...${s.substring(s.length - tailLength)}`;
    }

    static compare(a: string, b: string) {
      return (a > b) ? 1 :
              (a < b) ? -1 : 0;
    }

    static splitToLines(s: string, maxLineLength? : number) {
      if (!s || !maxLineLength || s.length < maxLineLength) {
        return s;
      }

      const indexOfLineEnds = s.indexOf('\n');
      if (indexOfLineEnds >= 0) {
        return EchoesUtils.String.splitToLines(s.substring(0, indexOfLineEnds), maxLineLength) + '\n' +
               EchoesUtils.String.splitToLines(s.substring(indexOfLineEnds + 1), maxLineLength);
      }

      const indexOfSpace: number = s.lastIndexOf(' ', maxLineLength);
      if (indexOfSpace < 0) {
        return s;
      }

      return s.substring(0, indexOfSpace) + '\n' +
             EchoesUtils.String.splitToLines(s.substring(indexOfSpace + 1), maxLineLength);
    }
  };

  /**
   * Math class
   */
  static Math = class {
    static round(value: number, precision: number) {
      const multiplier: number = Math.pow(10, precision || 0);
      return Math.round(value * multiplier) / multiplier;
    }
  };

  /**
   * Array class
   */
  static Array = class {

    static includes<T>(array: Array<T>, item: T): boolean {
      return array && item && array.includes(item);
    }
    
    /** Checks if array1 consists of any element of array2 */
    static includesAny<T>(array1: Array<T>, array2: Array<T>): boolean {
      if (array1 && array2) {
        for (const a of array2) {
          if (array1.includes(a)) {
            return true;
          }
        }
      }
      return false;
    }

    /*
    * Get safe length of array
    */ 
    static getLength<T>(array: Array<T>): number {
      return array ? array.length : undefined;
    }
  };
  
 }
