import { TypedJSON } from 'typedjson';

import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Cacheable } from 'ngx-cacheable';

import { Constants } from '../constants';

import { BackendUrlService } from './backend-url.service';
import { MessageService } from './message.service';

import { SurveyEntity } from '../types/entities/survey-entity';
import { QueryEntity } from '../types/entities/query-entity';
import { QuestionEntity } from '../types/entities/question-entity';
import { RawQueryDataEntity } from '../types/queries/raw-query-data-entity';
import { RawQueryResultEntity } from '../types/queries/raw-query-result-entity';

/*
use proxy:
https://medium.freecodecamp.org/the-best-ways-to-connect-to-the-server-using-angular-cli-b0c6b699716c
so add the proxy.conf.json to the project and run the server with command: ng serve --proxy-config proxy.conf.json
*/

@Injectable({
  providedIn: 'root'
})
export class BackendApiService {

  private static readonly HTTP_OPTIONS = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      // 'Authorization' : 'Basic ' + btoa(USER_NAME + ':' + PASSWORD),
      'Authorization' : 'Basic ' + Constants.CREDENTIALS,
    })
  };

  private static readonly CACHE_AGE_IN_SECONDS: number = 3600; // 1 hour

  private observableMainSurveyEntity: Observable<SurveyEntity>;
  private observableLifestylesSurveyEntity: Observable<SurveyEntity>;

  constructor(
    public backendUrlService: BackendUrlService,
    private http: HttpClient,
    private messageService: MessageService
  ) {}

  getMainSurvey(): Observable<SurveyEntity> {
    if (!this.observableMainSurveyEntity) {
      this.observableMainSurveyEntity = this.getSurvey(BackendUrlService.MAIN_SURVEY_UUID);
    }
    return this.observableMainSurveyEntity;
  }

  getLifestylesSurvey(): Observable<SurveyEntity> {
    if (!this.observableLifestylesSurveyEntity) {
      this.observableLifestylesSurveyEntity = this.getSurvey(BackendUrlService.LIFESTYLES_SURVEY_UUID);
    }
    return this.observableLifestylesSurveyEntity;
  }

  /** GET survey from the server */
  @Cacheable({maxAge: BackendApiService.CACHE_AGE_IN_SECONDS * 1000})
  getSurvey(surveyUuid: string): Observable<SurveyEntity> {
    if (!surveyUuid) {
      throw new Error("surveyUuid is undefined");
    }

    const url: string = this.backendUrlService.getSurveyFullUrl(surveyUuid);
    console.log(this.messageService.debug(
      'BackendApiService', 'getSurvey()', 'url', url));

    return this.http.get<SurveyEntity>(url, this.getHttpOptions()).pipe(
      // map(response => Object.setPrototypeOf(response, SurveyEntity.prototype)),
      map(response => {
        const surveyEntity: SurveyEntity = new TypedJSON(SurveyEntity).parse(response).init();
        surveyEntity.isMainSurvey = surveyEntity.uuid === BackendUrlService.MAIN_SURVEY_UUID;
        return surveyEntity;
      }),
      tap(survey =>
        console.log(this.messageService.debug(
          'BackendApiService', 'getSurvey()', 'survey fetched', !!survey))),
      catchError(this.handleError<SurveyEntity>('getSurvey', undefined)),
    );
  }

  // executeQuery(surveyEntity: SurveyEntity, queryEntity: QueryEntity): Observable<RawQueryDataEntity> {
  //   if (!surveyEntity) {
  //     throw new Error("surveyEntity is undefined");
  //   }

  //   if (!queryEntity) {
  //     throw new Error("queryEntity is undefined");
  //   }

  //   const url: string = this.backendUrlService.getQueryExecuteUrl(surveyEntity.uuid, queryEntity.uuid);
  //   return this.http.get<RawQueryDataEntity>(url, this.getHttpOptions()).pipe(
  //     map(response => new TypedJSON(RawQueryDataEntity).parse(response)),
  //     tap(response =>
  //       console.log(this.messageService.debug(
  //         'BackendApiService', 'executeQuery()', `response: (type ${typeof response})`, response))),
  //     catchError(this.handleError<RawQueryDataEntity>('executeQuery', undefined))
  //   );
  // }

  // /**
  // Execute queries with query_uuids
  //  */
  // executeQueries(surveyEntity: SurveyEntity, queryEntities: QueryEntity[]): Observable<RawQueryDataEntity> {
  //   if (!surveyEntity) {
  //     throw new Error("surveyEntity is undefined");
  //   }

  //   if (!queryEntities) {
  //     throw new Error("queryEntities is undefined");
  //   }

  //   const url: string = this.backendUrlService.getQueriesExecuteUrl(surveyEntity.uuid);
  //   const body = {
  //     query_uuids: queryEntities.map(q => q.uuid),
  //   };
  //   console.log(this.messageService.debug(
  //     'BackendApiService', 'executeQueries()', 'body', body));
  //   return this.http.post<RawQueryDataEntity>(url, body, this.getHttpOptions()).pipe(
  //     map(response => new TypedJSON(RawQueryDataEntity).parse(response)),
  //     tap(response =>
  //       console.log(this.messageService.debug(
  //         'BackendApiService', 'executeQueries()', `response: (type ${typeof response})`, response))),
  //     catchError(this.handleError<RawQueryDataEntity>('executeQuery', undefined))
  //   );
  // }

  // /**
  // Execute queries with constraint_uuids and indicator_uuids
  //  */
  // executeQueriesEx(constraintQuestions: QuestionEntity[], variableQuestions: QuestionEntity[]): Observable<RawQueryDataEntity> {
  //   const url: string = this.backendUrlService.getQueriesExecuteUrl();
  //   const body = {
  //     variable_question_uuids: variableQuestions.map(q => q.uuid),
  //     constraint_question_uuids: constraintQuestions.map(q => q.uuid),
  //   };
  //   console.log(this.messageService.debug(
  //     'BackendApiService', 'executeQueries()', 'body', body));
  //   return this.http.post<RawQueryDataEntity>(url, body, this.getHttpOptions()).pipe(
  //     map(response => new TypedJSON(RawQueryDataEntity).parse(response)),
  //     tap(response =>
  //       console.log(this.messageService.debug(
  //         'BackendApiService', 'executeQueries()', `response: (type ${typeof response})`, response))),
  //     catchError(this.handleError<RawQueryDataEntity>('executeQuery', undefined))
  //   );
  // }

  /**
  Execute queries with constraint_uuids and indicator_uuids
   */
  executeQueriesEx(surveyEntity: SurveyEntity, constraintQuestions: QuestionEntity[], indicatorQuestions: QuestionEntity[]): Observable<RawQueryResultEntity> {
    if (!surveyEntity) {
      throw new Error("surveyEntity is undefined");
    }

    if (!constraintQuestions) {
      throw new Error("constraintQuestions is undefined");
    }

    if (!indicatorQuestions) {
      throw new Error("indicatorQuestions is undefined");
    }

    const url: string = this.backendUrlService.getQueriesExecuteUrl(surveyEntity.uuid);
    const body = {
      constraint_uuids: constraintQuestions.map(q => q.uuid),
      indicator_uuids: indicatorQuestions.map(q => q.uuid),
    };
    console.log(this.messageService.debug(
      'BackendApiService', 'executeQueriesEx()', 'body', body));
    return this.http.post<RawQueryResultEntity>(url, body, this.getHttpOptions()).pipe(
      map(response => new TypedJSON(RawQueryResultEntity).parse(response)),
      tap(response =>
        console.log(this.messageService.debug(
          'BackendApiService', 'executeQueriesEx()', `response: (type ${typeof response})`, response))),
      catchError(this.handleError<RawQueryResultEntity>('executeQueriesEx', undefined))
    );
  }

  // /** GET backend from the server */
  // protected getEntities<T> (url : string, propertyName : string): Observable<T[]> {
  //   return this.http.get<T[]>(url)
  //     .pipe(
  //       map(response => {
  //         const j: any = response;
  //         return j.propertyName;
  //       }),
  //       tap(_ => this.messageService.log('fetched entities')),
  //       catchError(this.handleError<T[]>('getEntities', []))
  //     );
  // }

  // /** GET tag by id. Return `undefined` when id not found */
  // getBackendClientNo404<Data>(id: number): Observable<BackendClient> {
  //   const url = `${this.backendUrl}/?id=${id}`;
  //   return this.http.get<BackendClient[]>(url)
  //     .pipe(
  //       map(backend => backend[0]), // returns a {0|1} element array
  //       tap(h => {
  //         const outcome = h ? `fetched` : `did not find`;
  //         this.messageService.log(`${outcome} tag id=${id}`);
  //       }),
  //       catchError(this.handleError<BackendClient>(`getBackendClient id=${id}`))
  //     );
  // }

  // /** GET tag by id. Will 404 if id not found */
  // getBackendClient(id: number): Observable<BackendClient> {
  //   const url = `${this.backendUrl}/${id}`;
  //   return this.http.get<BackendClient>(url).pipe(
  //     tap(_ => this.messageService.log(`fetched tag id=${id}`)),
  //     catchError(this.handleError<BackendClient>(`getBackendClient id=${id}`))
  //   );
  // }

  // /* GET backend whose name contains search term */
  // searchBackendClients(term: string): Observable<BackendClient[]> {
  //   if (!term.trim()) {
  //     // if not search term, return empty tag array.
  //     return of([]);
  //   }
  //   return this.http.get<BackendClient[]>(`${this.backendUrl}/?name=${term}`).pipe(
  //     tap(_ => this.messageService.log(`found backend matching "${term}"`)),
  //     catchError(this.handleError<BackendClient[]>('searchBackendClients', []))
  //   );
  // }

  // //////// Save methods //////////

  // /** POST: add a new tag to the server */
  // addBackendClient (tag: BackendClient): Observable<BackendClient> {
  //   return this.http.post<BackendClient>(this.backendUrl, tag, httpOptions).pipe(
  //     tap((newBackendClient: BackendClient) => this.messageService.log(`added tag w/ id=${newBackendClient.id}`)),
  //     catchError(this.handleError<BackendClient>('addBackendClient'))
  //   );
  // }

  // /** DELETE: delete the tag from the server */
  // deleteBackendClient (tag: BackendClient | number): Observable<BackendClient> {
  //   const id = typeof tag === 'number' ? tag : tag.id;
  //   const url = `${this.backendUrl}/${id}`;

  //   return this.http.delete<BackendClient>(url, httpOptions).pipe(
  //     tap(_ => this.messageService.log(`deleted tag id=${id}`)),
  //     catchError(this.handleError<BackendClient>('deleteBackendClient'))
  //   );
  // }

  // /** PUT: update the tag on the server */
  // updateBackendClient (tag: BackendClient): Observable<any> {
  //   return this.http.put(this.backendUrl, tag, httpOptions).pipe(
  //     tap(_ => this.messageService.log(`updated tag id=${tag.id}`)),
  //     catchError(this.handleError<any>('updateBackendClient'))
  //   );
  // }

  insertSurveyAnswers(surveyEntity: SurveyEntity, answers: number[]): Observable<{}> {
    const url: string = this.backendUrlService.getAnswersInsertUrl(surveyEntity.uuid);
    const body = {
      question_uuids: surveyEntity.questions.map (q => q.uuid),
      answers: [{
        values: answers,
        row_id: Date.now(),
      }]
    };
    console.log(this.messageService.debug(
      'BackendApiService', 'exeinsertSurveyAnswercuteQueriesEx()', 'body', body));

    return this.http.post(url, body, this.getHttpOptions()).pipe(
      catchError(this.handleError('insertSurveyAnswers'))
    );
  }


  protected getHttpOptions() {
    return BackendApiService.HTTP_OPTIONS;
    // let httpHeaders = new HttpHeaders();
    // httpHeaders.append('Content-Type', 'application/json');
    // httpHeaders.append("Authorization", "Basic " + btoa("admin:admin")); 
    // return {
    //   headers: httpHeaders
    // };
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  protected handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {

      // copy from: https://scotch.io/bar-talk/error-handling-with-angular-6-tips-and-best-practices192
      let errorMessage: string = '';
      if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${error.error.message}`;
      } else {
        // server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }

      // TODO: send the error to remote logging infrastructure
      console.error(this.messageService.error('BackendApiService', operation, errorMessage, error));
      window.alert(errorMessage);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
