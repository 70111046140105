import { Component, OnInit, OnChanges, AfterViewInit, OnDestroy, DoCheck, Input,
         NgZone, IterableDiffers, IterableDiffer } from '@angular/core';
import { Observable } from 'rxjs';
// import { getClosureSafeProperty } from '@angular/core/src/util/property';

// import { Chart } from 'chart.js'; // npm install chart.js --save  (https://www.chartjs.org/)
// https://www.amcharts.com/docs/v4/chart-types/radar-chart/#Updated_demo
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4themes_kelly from '@amcharts/amcharts4/themes/kelly';

import { EchoesUtils } from '../../utils/echoes-utils';

import { QueryDataService } from '../../services/query-data.service';
import { MessageService } from '../../services/message.service';

import { CountryEntity } from '../../types/entities/country-entity';
import { QuestionEntity } from '../../types/entities/question-entity';
import { SurveyEntity } from '../../types/entities/survey-entity';

import { RawQueryResultEntity } from '../../types/queries/raw-query-result-entity';
import { QueryDataEntity } from '../../types/queries/query-data-entity';
import { QueryDataRecordEntity } from '../../types/queries/query-data-record-entity';

@Component({
  selector: 'app-spider-web-chart',
  templateUrl: './spider-web-chart.component.html',
  styleUrls: ['./spider-web-chart.component.scss']
})
export class SpiderWebChartComponent implements OnInit, OnChanges, AfterViewInit, DoCheck, OnDestroy {

  private static readonly SERIES_NAME_FIELD = 'name'; // defined by amcharts
  private static readonly INDICATOR_NAME_FIELD: string = 'indicatorName';
  private static readonly INDICATOR_CODE_FIELD: string = 'indicatorCode';

  private static readonly QUESTION_MAX_LINE_LENGTH: number = 40; // px
  private static readonly DEFAULT_CHART_FONT_SIZE: number = 16; // px
  private static readonly DEFAULT_CHART_SERIES_STROKE_WIDTH: number = 2; // px
  private static readonly ACTIVE_CHART_SERIES_STROKE_WIDTH: number = 8; // px    

  @Input()
  survey: SurveyEntity;

  @Input()
  selectedCountries: CountryEntity[];

  @Input()
  selectedQuestions: QuestionEntity[];

  @Input()
  constraintQuestions: QuestionEntity[];

  @Input()
  isMainSurvey: boolean;

  private chart: am4charts.RadarChart;

  private rawQueryResultCache: Observable<RawQueryResultEntity>;
  private queryData: QueryDataEntity;

  // radarChart;
  // chartDatasets: any[];

  private countriesDiffer: IterableDiffer<CountryEntity>;
  private questionsDiffer: IterableDiffer<QuestionEntity>;

  // chartColors = [
  //   'rgb(255, 99, 132)',
  //   'rgb(75, 192, 192)',
  //   'rgb(255, 159, 64)',
  //   'rgb(54, 162, 235)',
  //   'rgb(255, 205, 86)',
  //   'rgb(153, 102, 255)',
  //   'rgb(231, 233, 237)',
  // ];

  private switchAxes: boolean;
  private zoomValues: boolean;
  private showTooltips: boolean;
  private useQuestionCodesInTooltips: boolean;

  private chartFontSize: number = SpiderWebChartComponent.DEFAULT_CHART_FONT_SIZE;
  private legendFontSize: number = SpiderWebChartComponent.DEFAULT_CHART_FONT_SIZE;
  private tooltipFontSize: number = SpiderWebChartComponent.DEFAULT_CHART_FONT_SIZE;

  constructor(
    private zone: NgZone,
    private queryDataService: QueryDataService,
    private messageService: MessageService,
    private iterableDiffers: IterableDiffers
  ) {
    this.countriesDiffer = this.iterableDiffers.find([]).create(null);
    this.questionsDiffer = this.iterableDiffers.find([]).create(null);
  }

  ngOnInit(): void {
    if (!this.survey) {
      throw new Error('survey is undefined');
    }

    this.switchAxes = false;
    this.zoomValues = true;
    this.showTooltips = true;
    this.useQuestionCodesInTooltips = this.isMainSurvey;
  }

  ngOnChanges(): void {
    console.log(this.messageService.debug('SpiderWebChartComponent', 'ngOnChanges()', 'start'));
    this.displayChart();
  }

  ngAfterViewInit(): void {
    console.log(this.messageService.debug('SpiderWebChartComponent', 'ngAfterViewInit()', 'start'));
    this.zone.runOutsideAngular(() => {
      // set themes
      am4core.useTheme(am4themes_animated);
      am4core.useTheme(am4themes_kelly);

      // create chart
      const chart: am4charts.RadarChart = am4core.create('chart', am4charts.RadarChart);
      // chart.fontSize = 14;
      // chart.width = am4core.percent(100);
      // chart.height = am4core.percent(100);

      // https://www.amcharts.com/docs/v4/chart-types/xy-chart/
      // const chart: am4charts.XYChart = am4core.create('chart', am4charts.XYChart);

      /* Add legend */
      const legendContainer: am4core.Container = am4core.create('legend', am4core.Container);
      legendContainer.width = am4core.percent(100);
      legendContainer.height = am4core.percent(100);

      chart.legend = new am4charts.Legend();
      chart.legend.parent = legendContainer;

      chart.events.on('datavalidated', this.resizeLegend, this);
      chart.events.on('maxsizechanged', this.resizeLegend, this);

      chart.legend.itemContainers.template.events.on('over', this.changeSeriesActiveState, this);
      chart.legend.itemContainers.template.events.on('out', this.changeSeriesActiveState, this);

      /* Add cursor */
      chart.cursor = new am4charts.RadarCursor();
      this.chart = chart;
    });
  }

  ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  private displayChart(): void {
    if (!this.selectedCountries || !this.selectedQuestions) {
      return;
    }

    if (!this.rawQueryResultCache) {
      this.rawQueryResultCache = this.queryDataService.executeQueriesEx(this.survey, this.constraintQuestions, this.selectedQuestions);
    }

    this.rawQueryResultCache
      .subscribe(rawQueryResult => {
        if (!rawQueryResult || !this.chart) {
          return;
        }

        // define constraintQuestionFilterValues
        const constraintQuestionFilterValues: number[][] = [];
        for (const constraintQuestion of rawQueryResult.constraintQuestions) {
          if (constraintQuestion.isCountryQuestion()) {
            constraintQuestionFilterValues.push(this.selectedCountries.map(c => c.number));
          } else {
            constraintQuestionFilterValues.push(null);
          }
        }

        this.queryData = this.queryDataService.processQueryResult(rawQueryResult, constraintQuestionFilterValues);

        this.zone.runOutsideAngular(() => {
          this.updateDataSeries(this.chart, this.queryData);
        });        
        
        this.updateTooltips();
        this.updateFontSize();
      });

  }

  ngDoCheck(): void {
    let selectionChanged: boolean = false;
    if (this.countriesDiffer.diff(this.selectedCountries)) {
      selectionChanged = true;
      console.log(this.messageService.debug('SpiderWebChartComponent', 'ngDoCheck()', 'Selected countries changed'));
    }

    if (this.questionsDiffer.diff(this.selectedQuestions)) {
      selectionChanged = true;
      console.log(this.messageService.debug('SpiderWebChartComponent', 'ngDoCheck()', 'Selected questions changed'));
    }

    if (selectionChanged) {
      this.rawQueryResultCache = null;
      this.displayChart();
    }
  }

  private updateDataSeries(chart: am4charts.RadarChart, queryData: QueryDataEntity) {

    // Create axes
    // https://stackoverflow.com/questions/53910595/how-to-use-amcharts4-in-angular6
    chart.xAxes.clear();
    chart.yAxes.clear();

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis<am4charts.AxisRendererCircular>());
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererRadial>());

    if (!this.zoomValues) {
      let min: number = undefined;
      let max: number = undefined;
      for (const indicatorQuestion of this.selectedQuestions) {        
        if (indicatorQuestion.enum_values) {
          for (const enumValue of indicatorQuestion.enum_values) {
            if (!min || enumValue.value < min) {
              min = enumValue.value;
            }
            if (!max || enumValue.value > max) {
              max = enumValue.value;
            }
          }
        }
      }

      if (min && max) {
        valueAxis.min = min;
        valueAxis.max = max;
        valueAxis.strictMinMax = true;
      } else {
        this.zoomValues = true;
      }
    }

    // set series
    chart.series.clear();

    if (this.switchAxes) {

      const categoryDataField = QueryDataRecordEntity.COUNTRY_FIELD;
      categoryAxis.dataFields.category = categoryDataField;

      // add series
      for (const question of this.selectedQuestions) {
        const questionValueField: string = QueryDataRecordEntity.formatFieldName(question, QueryDataRecordEntity.VALUE_FIELD);
        // const questionTextField: string = QueryDataRecordEntity.formatFieldName(question, QueryDataRecordEntity.TEXT_FIELD);

        const series: am4charts.RadarSeries = chart.series.push(new am4charts.RadarSeries());
        series.dataFields.categoryX = categoryDataField;
        series.dataFields.valueY = questionValueField;
        console.log(this.messageService.debug('SpiderWebChartComponent', 'updateDataSeries()', 'series.dataFields.valueY', series.dataFields.valueY));

        series.name = question.getShortLabelWithName(80); //`${question.name}. ${question.short_label}`;
        series.strokeWidth = 3;
        series.zIndex = 2;
        series.sequencedInterpolation = true;
        series.sequencedInterpolationDelay = 100;

        // Used for showing tooltips (see method updateTooltips())
        series[SpiderWebChartComponent.INDICATOR_CODE_FIELD] = question.name;

        this.setSeriesProperties(series);
      }

      // set data
      console.log(this.messageService.debug('SpiderWebChartComponent', 'updateDataSeries()', 'queryData.records', queryData.records));
      chart.data = queryData.records;

    } else { // !this.switchAxes

      const categoryDataField = SpiderWebChartComponent.INDICATOR_NAME_FIELD;
      categoryAxis.dataFields.category = categoryDataField;

      // set series
      for (const country of this.selectedCountries) {
        const series = chart.series.push(new am4charts.RadarSeries());
        series.dataFields.categoryX = categoryDataField;
        series.dataFields.valueY = country.name;
        series.name = country.name;
        series.strokeWidth = 3;
        series.zIndex = 2;
        series.sequencedInterpolation = true;
        series.sequencedInterpolationDelay = 10;

        this.setSeriesProperties(series);
      }

      // set data
      const transposedRecords: QueryDataRecordEntity[] = [];
      for (const question of this.selectedQuestions) {
        const transposedRecord = new QueryDataRecordEntity();
        transposedRecord[categoryDataField] = EchoesUtils.String.splitToLines(
          question.getShortLabelWithName().replace(' (', '\n('),
          SpiderWebChartComponent.QUESTION_MAX_LINE_LENGTH);
          
        console.error(transposedRecord[categoryDataField]);

        const valueField: string = QueryDataRecordEntity.formatFieldName(question, QueryDataRecordEntity.VALUE_FIELD);

        for (const record of queryData.records) {
          transposedRecord[record.country] = record[valueField];
        }

        transposedRecords.push(transposedRecord);
      }

      chart.cursor = new am4charts.RadarCursor();

      console.log(this.messageService.debug('SpiderWebChartComponent', 'updateDataSeries()', 'transposedRecords', transposedRecords));

      chart.data = transposedRecords;
    }
  }

  private updateTooltips(): void {
    if (!this.chart || !this.chart.series) {
      return;
    }

    this.zone.runOutsideAngular(() => {
            
      for (const axis of this.chart.xAxes) {
        axis.tooltip.fontSize = this.tooltipFontSize;
      }

      const fieldName = (this.switchAxes && this.useQuestionCodesInTooltips) ?
        SpiderWebChartComponent.INDICATOR_CODE_FIELD : SpiderWebChartComponent.SERIES_NAME_FIELD;

      const tooltipText = this.showTooltips ?
        `[font-size: ${this.tooltipFontSize}px]{${fieldName}}: {valueY.formatNumber("#.##")}[/]` : null;

      for (const series of this.chart.series) {
        series.tooltipText = tooltipText;
      }
    });
  }

  private updateFontSize(): void {
    if (!this.chart) {
      return;
    }

    this.zone.runOutsideAngular(() => {
      this.chart.fontSize = this.chartFontSize;
      this.chart.legend.fontSize  = this.legendFontSize;
    });
  }

  private resizeLegend(event: any): void {
    this.zone.runOutsideAngular(() => {
      document.getElementById('legend').style.height = this.chart.legend.contentHeight + 'px';
    });
  }

  private setSeriesProperties(series: am4charts.RadarSeries): void {
    // series.defaultState.properties.strokeWidth = 1;
    series.strokeWidth = SpiderWebChartComponent.DEFAULT_CHART_SERIES_STROKE_WIDTH;
    series.segments.template.strokeWidth = SpiderWebChartComponent.DEFAULT_CHART_SERIES_STROKE_WIDTH;

    const activeState = series.segments.template.states.create('active');
    activeState.properties.strokeWidth = SpiderWebChartComponent.ACTIVE_CHART_SERIES_STROKE_WIDTH;
  }

  private changeSeriesActiveState(event: any): void {
    this.zone.runOutsideAngular(() => {
      const lineSeries = event.target.dataItem.dataContext['segments'].template;
      lineSeries.isActive = !lineSeries.isActive;
      // lineSeries.strokeWidth = lineSeries.isActive ? 5 : 1;

      // console.log(`lineSeries.isActive: ${lineSeries.isActive}`);
      // console.log(`lineSeries.strokeWidth: ${lineSeries.strokeWidth}`);
    });
  }

}
