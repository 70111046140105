import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { MessageService } from '../../services/message.service';
import { WebsiteSurveyService } from '../../services/website-survey.service';

import { SurveyEntity } from '../../types/entities/survey-entity';

@Component({
  selector: 'app-raw-data-page',
  templateUrl: './raw-data-page.component.html',
  styleUrls: ['./raw-data-page.component.scss']
})
export class RawDataPageComponent implements OnInit {

  private static readonly TIMEOUT = 1000;

  private survey: SurveyEntity;
  private surveySent: boolean;

  constructor(
    private router: Router,
    private websiteSurveyService: WebsiteSurveyService,
    private messageService: MessageService
    ) {
  }

  ngOnInit() {

    this.websiteSurveyService
      .getWebsiteSurvey()
      .subscribe(survey => {
        this.survey = survey;
      });

  }

  onSubmitAnswers(answers: number[]) {
    this.websiteSurveyService
      .insertSurveyAnswers(this.survey, answers)
      .subscribe(result => {
        this.surveySent = false;
        setTimeout(function() {
          this.surveySent = true;
        }.bind(this), RawDataPageComponent.TIMEOUT);

      });
      
  }

}
