import { jsonObject, jsonArrayMember } from 'typedjson';

@jsonObject
export class RawQueryResultIndicatorValuesEntity {
  @jsonArrayMember(Number)
  constraints: number[];

  @jsonArrayMember(Number, {dimensions: 2})
  value_counts: number[][];
}
